import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'assets/scss/indexV2.scss'


//---------------------------------------------------------------------------
const DenseStyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  whiteSpace: 'pre-line',
  padding: 10,
  color: 'var(--white-white-75)',
  borderBottom:'1px solid var(--green-alpha-alpha-24)',
  fontFamily:'Quantico',

  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    // fontSize: 16,
    // whiteSpace: 'pre-line',
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 16,
    // whiteSpace: 'pre-line',
  },
}));
export default DenseStyledTableCell
