// define link text and endpoint mapping
import paths from "./network/apiPath";

// text url
export function linkData(text, url) {
  return { text, url };
}

const auth = {
  login: linkData("Log In", paths.auth.login),
  logout: linkData("Log Out", ""),
  signup: linkData("Sign Up", paths.auth.signup),
  terms:{
    agree: linkData("Agree", paths.auth.signup),
    disagree: linkData("Disagree", paths.auth.signup),
  }
}

const _site = {
  home: {
    multi_search: linkData('Multifunctional Peptide', paths.spp.peptide.multi_search()),
    advanced_search: linkData('Advanced Search', paths.spp.peptide.advanced_search()),
  },
  
  nav: {
    home: linkData("Home", paths.site.home),
    proteins: linkData("Protein", paths.site.proteins),
    peptides: linkData("Peptide", paths.site.peptides),
    tools: linkData("Tools", paths.site.tools),
    statistics: linkData("Statistics", ''),
    products: linkData("Products", paths.site.products),
    about: linkData("About us", paths.site.about),
  },
  
  footer: {
    privacy: linkData("Privacy Policy", paths.site.privacy),
    terms: linkData("Terms & Conditions", paths.auth.terms),
  },
};

const site = Object.create(_site)
site.bread = Object.values(_site.nav).map((item) => {return {[item.text]: item.url}})
//---------------------------------------------------------------------------

function UniprotKB(value) {
  const result = (v) => {
    return (
      <u>
        <a
          href={`https://www.uniprot.org/uniprotkb/${v}/entry`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {v}
        </a>
      </u>
    );
  };

  let uniprotList = [];
  if (value.includes(",")) {
    uniprotList = value.split(",");
    return uniprotList.map((item) => {
      return (
        <>
          {result(item)}
          <br />
        </>
      );
    });
  }
  return result(value);
}

function PMID(value) {
  return (
    <u>
      <a
        href={`https://pubmed.ncbi.nlm.nih.gov/${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    </u>
  );
}
function htmlPMID(value) {
  return `<u><a href='https://pubmed.ncbi.nlm.nih.gov/${value}' target="_blank" rel="noopener noreferrer">${value}</a></u>`;
}

function DOI(value) {
  return (
    <u>
      <a
        href={`https://doi.org/${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    </u>
  );
}
function htmlDOI(value) {
  return `<u><a href=https://doi.org/${value} target="_blank" rel="noopener noreferrer">${value}</a></u>`;
}

function htmlBIOPEP_UWMSite(value) {
  return `<a href='http://www.uwm.edu.pl/biochemia/index.php/pl/biopep' target="_blank" rel="noopener noreferrer">${value}</a>`;
}
function htmlBIOPEP_UWM(value) {
  return `<u><a href='https://biochemia.uwm.edu.pl/biopep/peptidedatacard.php?zm_ID=${value}' target="_blank" rel="noopener noreferrer">${value}</a></u>`;
}

function htmlAPDSite(value) {
  return `<a href='http://aps.unmc.edu/AP/database/query_input.php' target="_blank" rel="noopener noreferrer">${value}</a>`;
}

function htmlBioPepDBSite(value) {
  return `<a href='http://bis.zju.edu.cn/biopepdbr/index.php?p=search' target="_blank" rel="noopener noreferrer">${value}</a>`;
}

function htmlMBPDBSite(value) {
  return `<a href='http://mbpdb.nws.oregonstate.edu/peptide_search' target="_blank" rel="noopener noreferrer">${value}</a>`;
}

function htmlToxinPredSite(value = "ToxinPred") {
  return `<a href='http://crdd.osdd.net/raghava/toxinpred/' target="_blank" rel="noopener noreferrer">${value}</a>`;
}

const outside = {
  UniprotKB,
  PMID,
  DOI,
  
  htmlPMID,
  htmlDOI,
  htmlBIOPEP_UWMSite,
  htmlBIOPEP_UWM,
  htmlAPDSite,
  htmlBioPepDBSite,
  htmlMBPDBSite,
  
  htmlToxinPredSite,
};
//---------------------------------------------------------------------------
const links = {
  auth,
  site,
  
  outside,
};
export default links;
