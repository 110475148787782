import { MenuItem, Select } from "@mui/material";

import 'assets/scss/fonts_colorsV2.scss';

//------------------------------------------------------------------------------
export const MuiSelect = (props) => {
  return (
    <Select variant="standard"
      sx={{
        color: 'var(--white-white-75)',
        fontFamily: 'Quantico',
        fontSize: '16px',
        '&:before': {
          borderBottom: '0.5px solid var(--white-white-16)'
        },
        '&:after': {
          borderBottom: '0.5px solid var(--green-alpha-alpha-24)'
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid var(--green-alpha-alpha-24)'
        },
        '& .MuiSelect-icon': {
          color: 'var(--white-white-75)'
        }
      }}
      {...props}
    >
    <MenuItem value=""><em>None</em></MenuItem>
    {props.children}
  </Select>)
}
export default MuiSelect

export const MuiSelectDark = (props) => {
  return (
    <Select variant="standard"
      sx={{
        color: 'var(--green-darker)',
        fontFamily: 'Quantico',
        fontSize: '16px',
        '&:before': {
          borderBottom: '0.5px solid var(--green-darker)'
        },
        '&:after': {
          borderBottom: '0.5px solid var(--green-dark-hover)'
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid var(--green-dark-hover)'
        },
        '& .MuiSelect-icon': {
          color: 'var(--green-darker)'
        }
      }}
      {...props}
    >
    <MenuItem value=""><em>None</em></MenuItem>
    {props.children}
  </Select>)
}