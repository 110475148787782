import React from 'react'

import scss from './GreenDiv.module.scss'
import Text from 'components/tag/Text'

//---------------------------------------------------------------------------
export const NoneDiv = (props) => (
  <div className={'layout'} {...props}>
    {props.children}
  </div>
)

//---------------------------------------------------------------------------
export default function GreenDiv(props) {
  return (
    <div className={scss.layout}>
      <div className={scss.frame}>
        <Text className='h1-darkGreen'>{props.children}</Text>
      </div>
    </div>
  )
};
