import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, Input, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

// -----custom tools-------------------------------------------------------
import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import scss from './ToolACEiPP.module.scss';
import scssPre from './ToolACEiPPPreLibraries.module.scss';
import scssTools from 'views/tool/ToolsV2.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import Text, { TagText } from 'components/tag/Text';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { RouterLink } from 'components/router/RouterLink';
import StyledTextField from 'components/tool/StyledTextField';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import { MuiTableCell4Head } from './ToolACEiPPSeqFeatures';
import fs, { fileNames, files } from 'utils/files';
import { spp } from 'utils/network/jsons';
import chartProp from 'components/chart/chartProp';
import LoadingAnime from 'components/LoadingAnime';
import ChartSection from 'components/chart/ChartSection';
import BarChart from 'components/chart/BarChart';
import { TableBodyCellPreLib, charts, tableHead, tables } from './ToolACEiPPPreLibraries';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GreenButton from 'components/button/GreenButton';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_anoxpp_pl
// path: paths.spp.tool.anoxpp_pl
const texts = {
  
  bread: breadData.create('Tools', 'AnOxP Prediction Pools', 'AnOxPP Pre-Libraries'),

  intro: "First, we use different enzymes (or combination enzymes) to simulate the hydrolysis of 21249 proteins from 60 species to obtain hydrolyzed fragments, and remove illegal sequences among them. "+
  "Then, search the SmartPP database to determine whether the obtained fragments are antioxidative peptides (AnOxPs), and screen out unreported sequences. "+
  "Finally, use AnOxPP to predict these sequences, screen peptides with a certain probability range, and add them to the data pool."
  ,
}

const jsonsTable = spp.tool.aceipp_anoxpp_pre_pool_table

//---------------------------------------------------------------------------
const ToolAnOxPPPreLibraries = ({ setInfo }) => {
  charts.title = [
    'AnOxPs reported by SmartPP database',
    'Not reported fragments searched by SmartPP database',
    'Number of pre-non-AnOxPs with predicted probability < 0.50',
    'Number of pre-AnOxPs with predicted probability ≥ 0.50',
    'Number of pre-AnOxPs with predicted probability ≥ 0.90',
    'Number of pre-AnOxPs with predicted probability ≥ 0.95',
  ]
  
  //---------------------------------------------------------------------------
  // chart
  const [arrDatasets, setArrDatasets] = useState([])
  const [chartLabels, setLabels] = useState([])
  const [chartDatasets, setDatasets] = useState([])
  
  // chart setting
  const chartOptions = chartProp.options.axisTitleXY(charts.chartLabelX, charts.chartLabelY)
  const chartData = chartProp.data.carate(
    "",
    "",
    [''],
    chartLabels, //"x_axis"
    chartDatasets,
  )
  
  //---------------------------------------------------------------------------
  // const chartMethod = charts.method
  const [selectChartIndex, setSelectChartIndex] = useState()
  // const [selectChartMethod, setSelectChartMethod] = useState()
  const [selectBlockTitle, setSelectBlockTitle] = useState(charts.title[0])
  
  function handleClickMethod(index) {
    // let method = index+1
    setSelectChartIndex(index)
    // setSelectChartMethod(method)
    // console.log('index', index);
    // console.log('method', method);
    
    let datasets = arrDatasets[index] ?? []
    // console.log(arrDatasets, datasets);
    setDatasets(datasets)
    setSelectBlockTitle(charts.title[index] ?? '')
  }
  
  //------------------------------------------------------------------------------
  // table
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiTableResult = () => {
    const input = jsonsTable.input(jsonsTable.dataset.AnOxPs)
    const config = apiConfig.tool.aceipp_anoxpp_pre_pool_table(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        // console.log(data)
        let items = data[spp.common.output.items]
        setTableItems(items)
        // console.log('items', items);
        
        //---------------------------------------------------------------------------
        // chart of Hydrolysis
        const arrH = items.filter((item) => tables.enzymeValueHide.includes(item["enzyme"]) === false)
        let arrDatasets = charts.getArrDatasets(arrH)
        setArrDatasets(arrDatasets)
        // console.log('arrH', arrH);
        // console.log('arrDatasets', arrDatasets);
        
        let arrPro = arrH.filter((item) => item["probability"] === charts.methodJsonValue[0])
        let labels = arrPro.map(item => String(item["enzyme"]).removeAll('\n'))
        setLabels(labels)
        // console.log(arrRep);
        // console.log('labels', labels);
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  useEffect(() => {
    apiTableResult()
  }, [])
  
  useEffect(() => {
    //setup init chart data with arrDatasets[0]
    handleClickMethod(0)
  }, [chartLabels])
  
  //----render-----------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.intro}>
      <Box className={scssTools.layout}>
        <div className={scss.frame_intro} style={{}}>
          <TagText className={'Contents-QuanticoBody16px-Medium-White_75'} html={texts.intro}></TagText>
        </div>
        
        <div className={scssPre.chart_type_btns}>
          {charts.methodBtnList.map((name, index) => {
              return (
                <GreenButton
                  key={index}
                  index={index}
                  className={selectChartIndex === index ? scssPre.selected_chart_type : ""}
                  onClick={() => {handleClickMethod(index)}}
                >{name}</GreenButton>)
            })
          }
        </div>
        
        {stateTable.isLoading ? <LoadingAnime />
        : <ChartSection
            sectionTitle={charts.chartTitle}
            chartBlockTitle={selectBlockTitle}
            mainChart={
              <BarChart
                data={chartData}
                options={chartOptions}
                showTableLabel={false}
                yAxisLabel={charts.chartLabelY}
                showAvgLine={true}
              />
            } />
        }
        
        <MuiTableContainer>
          <TableHeadRow>
            {tables.tableHeadCell.map((title, index) => (
              tableHead(title, index)
            ))}
          </TableHeadRow>
          <TableBody>
            {tableItems.map((item, index) => (
              <TableRow2Body key={index}>
                <TableBodyCellPreLib key={index} index={index}
                  dataset={jsonsTable.dataset.AnOxPs}
                  // filesList={files.ToolAnOxPP.PreLibraries} 
                  filePath={fs.filePaths.ToolACEiPP.PreLibraries} 
                  tableBodyCell={tables.tableBodyCell}>
                  {item}
                </TableBodyCellPreLib>
              </TableRow2Body>
            ))}
          </TableBody>
        </MuiTableContainer>
      </Box >
    </LayoutPageV2 >
  )
}
export default ToolAnOxPPPreLibraries