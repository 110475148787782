import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, IconButton, Input, MenuItem, Select, TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// -----custom tools-------------------------------------------------------
import 'assets/scss/commonV2.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from './ToolACEiPPPreLibraries.module.scss';
import scssACEiPP from './ToolACEiPP.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import Text, { TagText } from 'components/tag/Text';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { RouterLink } from 'components/router/RouterLink';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import fs, {fileNames, files} from 'utils/files';
import { spp } from 'utils/network/jsons';
import chartProp from 'components/chart/chartProp';
import LoadingAnime from 'components/LoadingAnime';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import { getKeyByValue } from 'utils/object';
import { Space, navigateNewTab } from 'utils/general';
import { MuiTableCell4Head, chartDataBtnList, chartTextData } from './ToolACEiPPSeqFeatures';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_aceipp_anoxpp_mr?dataset=1
// http://localhost:3000/tool_aceipp_anoxpp_mr?dataset=2
// path: paths.spp.tool.aceipp_anoxpp_mr
const jsons = spp.tool.aceipp_anoxpp_manual_result
const texts = {
  tableHeadCell: jsons.items_text,
  tableBodyCell: jsons.items,
  
  tool_names: [
    'ACEiPP',
    'AnOxPP',
  ],
  nonActivity: 'non-',
}

// for Mutation button
export const cellMutation = (navigate, value, dataset) => {
  // console.log(name, dataset);
  if( value !== undefined ) {
    return (
    <a className={'link'} onClick={() => eventClickMutation(navigate, value, dataset)}>
      <CalendarViewWeekIcon />
    </a>)
  } else
    return null
}

// for Site link on Peptide Mutation table
export const cellMutationText = (navigate, value, dataset, site) => {
  // console.log(name, dataset);
  if( value !== undefined ) {
    return (
    <a className={'link'} onClick={() => eventClickMutation(navigate, value, dataset, site)}>
      {site}
    </a>)
  } else
    return null
}

function eventClickMutation(navigate, value, dataset, site = 1) {
  navigate(paths.spp.tool.aceipp_anoxpp_mm(dataset, value, {site: site}))
  window.location.reload()
}

//------------------------------------------------------------------------------
export const cellPeptide = (navigate, value, dataset) => {
  // console.log(name, dataset);
  if( value !== undefined ) {
    return (
    <a className={'link'} >
      <FormatListBulletedIcon onClick={() => eventClickPeptide(navigate, value, dataset)}/>
    </a>)
  } else
    return null
}

function eventClickPeptide(navigate, value, dataset, site = 1) {
  navigate(paths.spp.tool.aceipp_anoxpp_mp(dataset, value, {site: site}))
  // window.location.reload()
}

//---------------------------------------------------------------------------
const ToolACEiPPAnOxPPManualResult = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const datasetStr = searchParams.get(paths.params.dataset)
  const dataset = Number(datasetStr)
  // console.log('dataset', dataset);
  
  let manualInput = cookie.getCookie(cookie.keys.tool.aceippManualInput)
  if( dataset === jsons.dataset.AnOxPs )
    manualInput = cookie.getCookie(cookie.keys.tool.anoxppManualInput)
  // console.log('manualInput', manualInput);
  
  const datasetName = getKeyByValue(jsons.dataset, dataset)
  const datasetNameNon = texts.nonActivity + datasetName
  // console.log(datasetName);
  const toolIndex = dataset - 1
  const toolName = (toolIndex >= 0 && toolIndex < texts.tool_names.length) ? texts.tool_names[toolIndex] : ''
  
  const keyOutput = jsons.output
  const keyError = jsons.items_error
  const [tableOutput, setTableOutput] = useState({})
  const words = {
    bread: breadData.create('Tools', `${toolName}-1.0 Server Output`, `${toolName} Pre-${datasetName}`, `Manual Result` ),
    
    mission: 
`Mission completed:
    # Submit time: ${tableOutput[keyOutput[0]]}
    # Time cost: ${tableOutput[keyOutput[1]]}s.
    # A total of ${tableOutput[keyOutput[2]]} sample(s) were/was submitted for this task.
    # ${tableOutput[keyOutput[3]]} error(s) occurred.
    # A total of ${tableOutput[keyOutput[4]]} ${datasetName} and ${tableOutput[keyOutput[5]]} ${datasetNameNon} were/was predcited.
For complete information, please see the following output (Only the top 200 samples are shown in this interface):`
    ,
  }
  
  //------------------------------------------------------------------------------
  // table
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiTableResult = () => {
    const input = jsons.input(dataset, manualInput)
    const config = apiConfig.tool.aceipp_anoxpp_manual_result(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        setTableOutput(data)
        // console.log(data)
        
        let items = data[spp.common.output.items]
        setTableItems(items)
        // console.log('items', items);
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  function getCellAlign(index) {
    if(index <= (2)|| index === 4) //'No.'~'Pre-Score'
      return 'left'
    if(index === 9 || index === 7) //Mutation
      return 'center'
    return 'right'
  }
  
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    
    let value, text, align, colSpan
    let isMutation, isPeptide
    let textError = children[keyError]
    let isError = (textError !== undefined)
    let className = (textError !== undefined) ? 'color-red' : ''
    
    return (
      tableBodyCell.map((cell, indexX) => {
        value = children[cell]
        text = value
        // console.log('cell', cell, 'value', value);
        
        colSpan = 1
        isMutation = false
        isPeptide = false
        
        if(isError) {
          if(indexX === 2) {
            text = textError
            colSpan = 6
          }
          if(indexX > 2 )
            return null
        }
        
        align = 'right'
        if(indexX <= 1 ) //'Name'~'Pre-Score'
          align = 'left'
        if(cell === "pre_activity") {
          if( value )
            text = datasetName
          else
            text = datasetNameNon
        }
        if(cell === "pre_activity") { 
          align = 'left'
          value = children["sequence"]
        }
        if(cell === "mutation") { //Mutation
          isMutation = true
          align = 'center'
          value = children["sequence"]
        }
        if(cell === 'peptide') {
          isPeptide = true
          align = 'center'
          value = children["sequence"]
        }
        
        return (
          <MuiTableCell key={index+''+indexX+''+cell}
            align={align} colSpan={colSpan} className={className}
            style = {{paddingTop: '2px', paddingBottom: '2px', height: '32px',fontFamily:'Quantico'}}>
            {isMutation
            ? cellMutation(navigate, value, dataset)
            : isPeptide
              ? cellPeptide(navigate, value, dataset)
              : text}
          </MuiTableCell>)
      }))
  }
  
  
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiTableResult()
  }, [])
  
  const tableCol = texts.tableHeadCell.length
  //----render-----------------------------------------------------------------
  return (
    <LayoutPageV2 bread={words.bread}>
      
        <div className={scssACEiPP.frame_intro}>
          <Text pre className={'Contents-QuanticoBody16px-Medium-White_75'}>{words.mission}</Text>
        </div>
        
        <MuiTableContainer>
          <TableHeadRow> {/* title */}
            {texts.tableHeadCell.map((title, index) => (
              <MuiTableCell4Head key={index} index={index} align={getCellAlign(index)} style={{fontFamily:'Quantico'}}>
                {title}</MuiTableCell4Head>
            ))}
          </TableHeadRow>
          <TableBody >
            {stateTable.isLoading
            ? <MuiTableCell colSpan={tableCol}>
                <LoadingAnime />
              </MuiTableCell>
            : tableItems.map((item, index) => (
              <TableRow2Body key={index}>
                <MuiTableCell component="th" scope="row" align='center'>{index+1}</MuiTableCell>
                <TableBodyCell key={index} index={index} tableBodyCell={texts.tableBodyCell}>
                  {item}
                </TableBodyCell>
              </TableRow2Body>
            ))}
          </TableBody>
        </MuiTableContainer>
      
    </LayoutPageV2 >
  )
}
export default ToolACEiPPAnOxPPManualResult