import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';

import 'assets/scss/indexV2.scss'

//---------------------------------------------------------------------------
export const MuiTableContainer = (props) => {
  const isPurple = props.isPurple ? props.isPurple : false
  const className = isPurple ? 'table_container_purple' : 'table_container'
  return (
  <TableContainer variant="outlined" className={className}>
    <Table aria-label="table" {...props}>
      {props.children}
    </Table>
  </TableContainer>
)}

export const MuiTableContainerPurple = (props) => (
  <TableContainer variant="outlined"  sx={{ border: '1px solid var(--logo-colors-logo-purple-16)', borderRadius:'2px'}}>
    <Table aria-label="table" {...props}>
      {props.children}
    </Table>
  </TableContainer>
)

export const MuiTableContainerNoneBorder = (props) => (
  <TableContainer variant="outlined" style={{border:'none'}}>
    <Table aria-label="table" {...props}>
      {props.children}
    </Table>
  </TableContainer>
)

export const TableHeadRow = (props) => (
  <TableHead {...props}>
    <TableRow
      sx={{
        background: 'var(--green-alpha-alpha-20)',
        // borderBottom: '3px solid var(--black-black)',
        // Height:'50px'
        // borderRightColor: 'var(--green-alpha-alpha-98)'
      }}>
      {props.children}
    </TableRow>
  </TableHead>
)
export const TableHeadRowPurple = (props) => (
  <TableHead {...props}>
    <TableRow
      sx={{
        background: 'var(--logo-colors-logo-purple-16)',
        // borderBottom: '3px solid var(--black-black)',
        // Height:'50px'
        // borderRightColor: 'var(--green-alpha-alpha-98)'
      }}>
      {props.children}
    </TableRow>
  </TableHead>
)

export const MuiTableBody = (props) => (
  <TableBody sx={{ border: 'none', }} >
    {props.children}
  </TableBody>
)

export const TableRow2Body = (props) => {
  let _sx = { '&:last-child td, &:last-child th': { border: 'none' }}
  if (props.sx !== undefined)
    Object.assign(_sx, props.sx)
  // console.log(_sx);
  return (
    <TableRow sx={_sx} {...props} className={`${props.className} Contents-QuanticoBody16px-Medium-White_75`}>
      {props.children}
    </TableRow>)
}

export const MuiTableCell = ({ ...props }) => {
  let sxs = {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 'bold',
      whiteSpace: 'pre-line',
      color: 'var(--white-white-75)',

      boxSizing: 'border-box',
      maxWidth: '180px',
      border:'none',
      borderRight:'1px solid var(--green-alpha-alpha-16)'
    },
    [`&.${tableCellClasses.body}`]: {
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',

      height: '80px',
      boxSizing: 'border-box',
      maxWidth: '180px',
      backgroundColor: 'var(--black-black)',
      color: 'var(--white-white-75)',

      borderBottom: 'solid 1px var(--green-alpha-alpha-20)',
    },
  }
  if (props.sx !== undefined)
    Object.assign(sxs, props.sx)
  return (
    <TableCell sx={sxs} {...props}>
      {props.children}
    </TableCell>
  )
}
export const MuiTableCellPurple = ({ ...props }) => {
  let sxs = {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 'bold',
      whiteSpace: 'pre-line',
      color: 'var(--white-white-75)',

      boxSizing: 'border-box',
      maxWidth: '180px',
      border:'none',
      borderRight:'1px solid var(--logo-colors-logo-purple-16)'
    },
    [`&.${tableCellClasses.body}`]: {
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',

      height: '80px',
      boxSizing: 'border-box',
      maxWidth: '180px',
      backgroundColor: 'var(--black-black)',
      color: 'var(--white-white-75)',

      borderBottom: 'solid 1px var(--logo-colors-logo-purple-16)',
    },
  }
  if (props.sx !== undefined)
    Object.assign(sxs, props.sx)
  return (
    <TableCell sx={sxs} {...props}>
      {props.children}
    </TableCell>
  )
}