import v from 'assets/scss/_variables.scss';
import 'assets/scss/colors.css'

//---------------------------------------------------------------------------
// eslint-disable-next-line no-extend-native
String.prototype.removeAll = function (tag) {
  return this.replaceAll(tag, '')
}

String.prototype.trimClassifyName = function () {
  let text = this
  text = text.removeAll(' peptides')
  // text = text.removeAll(' Peptides')
  // console.log(text)
  return text
}

// eslint-disable-next-line no-extend-native
String.prototype.removeHTMLTags = function () {
  return this.replace(/<\/?[^>]+(>|$)/g, "");
}

// eslint-disable-next-line no-extend-native
String.prototype.replaceHTML = function () {
  let str = this
  // console.log('str=', str)
  if(str) { //NOT undefined, null, ""
    // Change the background color of table header from API(Value with html tag)
    str = str.replaceAll('rgb(219, 238, 243)','var(--green-alpha-alpha-16)') 
  }
  // console.log('str=', str)
  return (str)
}

// eslint-disable-next-line no-extend-native
String.prototype.trimHTML = function () {
  let str = this
  // console.log('str=', str)
  if( str.includes('<sup>') ) { //1st [x]
    let st = str.indexOf('<sup>') //<sup>[1-2]</sup>
    let ed = str.indexOf('</sup>')
    str = str.substr(0,st) + str.substr(ed + String('</sup>').length)
    // console.log('text=', text)
    // console.log('output=', output)
  }
  if( str.includes('<sup>') ) { //2nd [x]
    let st = str.indexOf('<sup>') //<sup>[1-2]</sup>
    let ed = str.indexOf('</sup>')
    str = str.substr(0,st) + str.substr(ed + String('</sup>').length)
  }
  // console.log('str=', str)
  return (str)
}

// eslint-disable-next-line no-extend-native
String.prototype.to1stUpperCase = function () {
  let str = this
  if (!str)
    return ''; // 處理空字串或 null
  return str.charAt(0).toUpperCase() + str.slice(1);
}


//---------------------------------------------------------------------------
// eslint-disable-next-line no-extend-native
String.prototype.toCode = function () {
  let text = this
  let arrText = splitString(text, 10)
  let arrTextSpace = insertSpace(arrText)
  let arrTextLine = insertLine(arrTextSpace)
  let arr = createArray10(text.length)
  let arr10 = arr.map((num) => (formatNumberLength(num, 10)))
  let arr10Space = insertSpace(arr10)
  let arr10Line = insertLine(arr10Space)
  if( arr10Line.length < arrTextLine.length && (arr10Line.length % 8) === 0 ) //id=SPPPR8493
    arr10Line.push('\n')
  // console.log('arr10Line', arr10Line);
  // console.log('arrTextLine', arrTextLine);
  
  let str = String('')
  for(let i = 0; i < (arrTextLine.length / 8); i++)
  {
    let start = i*8
    for(let j = 0; j < 8; j++)
      str += (arr10Line[start + j] ? arr10Line[start + j] : '')
    for(let k = 0; k < 8; k++)
      str += (arrTextLine[start + k] ? arrTextLine[start + k] : '')
  }
  return (str)
}
function createArray10(length) {
  let count = length/10
  return (
    Array.from({length: count}, (_, i) => Math.round(i+1) * 10)
)}
function insertSpace(arrText) {
  return arrText.map((text) => (text+' '))
}
function insertLine(arrText) {
  return arrText.map((text, index) => (
      (((index+1) % (8)) === 0 || (index === arrText.length-1) ) //id=DFBPPR8484 DFBPPR8492
      ? text+'\n' : text
  ))
}
function splitString(str, N) {
  const arr = []
  for (let i = 0; i < str.length; i += N) {
    arr.push(str.substring(i, i + N))
  }
  return arr
}
function formatNumberLength(num, length) {
    var r = "" + num;
    while (r.length < length) {
        r = " " + r;
    }
    return r;
}
//---------------------------------------------------------------------------
