import { Link } from 'react-router-dom';
import React from 'react';

import 'assets/scss/index.scss';
import scss from './FooterV2.module.scss';
import imgs from 'utils/imgs';
import links from 'utils/links';
import Text, { TextLink } from 'components/tag/Text';
import Img, { ImgLink } from 'components/tag/Img';
import { DivLink } from 'components/tag/Div';

//---------------------------------------------------------------------------
const texts = {
  logo: '[Client Logo]',
  phone: 'Tel: 4006-888-888',
  email: 'cs@amway.com',
  // download: 'Download PDF',
  // please: 'Please cite: Qin D, Bo W, Zheng X, Hao Y, Li B, Zheng J, Liang G. SmartPP: A Comprehensive Database of \n'+
  //     'Food-Derived Bioactive Peptides for Peptidomics Research. Bioinformatics, 38(12), 2022, 3275-3280.',
  amway: '© 2023 Amway. All Rights Reserved. ICP No. 00000000',
}
//---------------------------------------------------------------------------
export default function FooterV2() {
  
  const css = {
    text_copy: 'Contents-QuanticoCaption12px-Regular-White_50',
    text_bottom: 'Contents-QuanticoCaption12px-Regular-White_50-Btn',
  }
  
  return (
    <React.Fragment>
      <div className={scss.frame_light}>
        <div className={scss.light}>
          <div className={scss.light_line3} />
          <div className={scss.light_line4} />
        </div>
      </div>
      
      <div className={scss.layout}>
        
        <div className={scss.frame_top}>
          <ImgLink imgName={imgs.siteV2.footer.logo} link={links.site.nav.home}
            className={scss.top_logo}></ImgLink>
            
          {/* <div className={scss.top_icon}>
            <Img imgName={imgList.footer.instagram}></Img>
            <Img imgName={imgList.footer.facebook}></Img>
            <Img imgName={imgList.footer.twitter}></Img>
            <Img imgName={imgList.footer.youtube}></Img>
          </div> */}
        </div>

        {/* <div className={scss.frame_center}>
        </div> */}

        <div className={scss.frame_bottom}>
          <Text className={css.text_copy}>{texts.amway}</Text>
          <div className={scss.line_5} />
          
          <a href={`${texts.phone}`}>
            <DivLink className={scss.button} text={css.text_bottom} >
              {texts.phone}
            </DivLink></a>
          <div className={scss.line_5} />
          
          <a href={`mailto:${texts.email}`}>
            <DivLink className={scss.button} text={css.text_bottom} >
              {texts.email}
            </DivLink></a>
          <div className={scss.line_5} />
          
          <DivLink className={scss.button}
            text={css.text_bottom} link={links.site.footer.privacy} />
          <div className={scss.line_5} />
          
          <DivLink className={scss.button}
            text={css.text_bottom} link={links.site.footer.terms} />
        </div>
      </div>
    </React.Fragment>
  )
}