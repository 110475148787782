import { useLocation } from 'react-router-dom';

import paths from './network/apiPath';

//------------------------------------------------------------------------------
const useLocationSPP = () => {
  const location = useLocation();
  const fullpath = location.pathname + location.search
    
  return {
    ...location,
    fullpath: fullpath,
    isHotspotCssResult: (location.pathname === paths.spp.tool.hotspot_css_tool_result()),
    isCssResultLv3: (location.pathname === paths.spp.tool.css_tool_result_lv3()),
    isStatisticProteinLv2: (location.pathname === paths.spp.statistic.protein_lv2()),
  };
};

export default useLocationSPP;
