/* 
Stacked Bar Chart | react-chartjs-2
https://react-chartjs-2.js.org/examples/stacked-bar-chart/
Bar Chart | Chart.js
https://www.chartjs.org/docs/latest/charts/bar.html
 */
import { Box, Slider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  scales,
} from 'chart.js';

import v from 'assets/scss/_variables.scss';
import "assets/scss/common.scss";
import csScss from "components/chart/ChartSection.module.scss";
import Text from 'components/tag/Text';
import { spp } from 'utils/network/jsons';

//---------------------------------------------------------------------------
/* 
// Append '4d' to the colors (alpha channel), except for the hovered index
function handleHover(evt, item, legend) {
    // legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => { //default location
    legend.chart.options.backgroundColor.forEach((color, index, colors) => {
        // console.log('color.length', color.length); //#000000 length=7, #0000004D length=9
        colors[index] = index === item.index || color.length === (7+2) ? color : color + '4D';
    });
    legend.chart.update();
}

// Removes the alpha channel from background colors
function handleLeave(evt, item, legend) {
    // legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => { //default location
    legend.chart.options.backgroundColor.forEach((color, index, colors) => {
        colors[index] = color.length === (7+2) ? color.slice(0, -2) : color;
    });
    legend.chart.update();
}
 */

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,

  PointElement,
  LineElement,
  Tooltip,
  scales,
);

//---------------------------------------------------------------------------
const StackedBarChart = ({ data, options,
  showTableLabel = false,
  yAxisLabel, needHoverStyle = false,
  handleClickBar, handleClickLabel,
  tooltipCRSresult,
  showTooltipTitle = true,
  ...props }) => {

  const chartRef = useRef(null);

  if (data === undefined) {
    data.labels = []
    data.datasets = []
  }
  
  let _data = data;
  let _options = {
    plugins: {
      title: {
        display: false,
        // text: '',
      },
      legend: {
        display: showTableLabel, //hide labels
        /* position: 'top', //top, bottom, left, right
        align: 'center', //start, center, end
        onHover: handleHover,
        onLeave: handleLeave,
        onClick: handleClick, */
      },
      // https://stackoverflow.com/questions/64894152/how-to-change-the-position-of-the-tooltip-for-the-bar-type-chart-in-chart-js
      tooltip: {
        yAlign: 'bottom',
        // xAlign: 'top',
      },
    },
    /* borderWidth: 1,
    borderColor: [
        '#0061A2',
    ], */
    backgroundColor: [
      '#3398db', //'#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'
    ],
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'white',
          font:{
            size:14,
            family: 'Quantico',
          }
        },
      },
      // y: {
      //   grid: {
      //     color: 'grey', 
      //     lineWidth: 0.5 
      //   },
      //   ticks: {
      //     color: 'white',
      //     font:{
      //       size:14,
      //       family: 'Quantico',
      //     }
      //   },
      //   title: {
      //     // align: 'end', //start, center, end
      //     display: false,
      //   },
      // },
      y: {
        stacked: true,
        // https://stackoverflow.com/questions/15751571/change-the-y-axis-values-from-real-numbers-to-integers-in-chart-js
        ticks: {
          stepSize: 1,
          color: 'white',
        },
      },
    },
  }
  if (options !== undefined)
    Object.assign(_options, options)
  
  /*
  Tooltip | Chart.js
  https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
   */
  // console.log('tooltipCRSresult', tooltipCRSresult);
  const jsonsChart = spp.tool.css_tool_result_lv1_bar_chart
  const tooltipCRS = (context) => {
    // console.log(context);
    let label = context.dataset.label || '';

    if (label) {
      label += ': ';
      label += context.formattedValue
    }
    if (context.parsed.y !== null) {
      // label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
      
      let idx = context.dataIndex
      // console.log('idx', idx);
      let text = '=';
      if( tooltipCRSresult !== undefined && Array.isArray(tooltipCRSresult) ) {
        let item = tooltipCRSresult[idx]
        let count = item[jsonsChart.items[3]] //"count_list",
        // let list = item[jsonsChart.items[4]] //"label_list",
        count.forEach((item, index) => {
          text += `${item}`;
          if(index !== count.length -1)
            text += '+'
        }); 
        label += text
      }
    }
    return label;
  };
  /* const footer = (items) => {
    let sum = 0;
    items.forEach(function(item) {
      sum += item.parsed.y;
    });
    return 'Sum: ' + sum;
  }; */
  if( tooltipCRSresult !== undefined ) {
    Object.assign(_options.plugins.tooltip, {
      callbacks: {
        title: function() {
          return 'Total'
        },
        label: tooltipCRS,
        // footer: footer,
    }})
  }
  
  if( !showTooltipTitle ) {
    Object.assign(_options.plugins.tooltip, {
      callbacks: {
        title: function() {
          return ''
        },
    }})
  }
  
  function handleClick(event) {
    if (chartRef && chartRef.current) {
      const chart = ChartJS.getChart(chartRef.current)
      if( chart !== undefined && Object.isObject(chart) ) {
        // https://www.chartjs.org/docs/latest/developers/api.html#getelementsateventformode-e-mode-options-usefinalposition
        const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
        // console.log('elements', elements);
        
        if( elements.length > 0 ) {
          if(handleClickBar !== undefined)
            handleClickBar(event, elements)
        } else {
          const elementsLabel = chart.getElementsAtEventForMode(event, 'x', false) //x label
          // console.log('elementsLabel', elementsLabel);
          
          if (elementsLabel.length > 0) {
            // console.log(elementsLabel[0].index) // Here clicked label | data index
            if(handleClickLabel !== undefined)
              handleClickLabel(event, elementsLabel, elementsLabel[0].index)
          }
        }
      }
    }
  }
  
  function handleMouseMove(event) {
    if (chartRef && chartRef.current) {
      const canvas = chartRef.current.canvas;
      canvas.style.cursor = 'pointer';
    }
  };
  
  return (
    <div className={csScss.main_stacked_bar_chart}>
      <div style={{width: '70%'}}>
        <Text center><h2>{data.title}</h2></Text>
        <Text center style={{ color: 'gray' }}><h4>{data.text}</h4></Text>
        <Bar
          data={_data}
          options={_options}
          ref={chartRef}
          onClick={handleClick}
          onMouseMove={needHoverStyle ? handleMouseMove : null}
          {...props} />
      </div>
    </div>
  )
}
export default StackedBarChart