import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import v2 from 'assets/scss/_variablesV2.scss';
//---------------------------------------------------------------------------
export function listDate(label, init) { //initial
  return { label, init }
}

//---------------------------------------------------------------------------
export default function TextList(props) {
  const index = props.index ? props.index : 0;
  const label = props.option ? props.option[index].label : '';
  const fontSize = props.fontSize ? props.fontSize : '16px';
  // console.log('index=', index, ' props.index=', props.index);
  // console.log('label=', label, ' props.option[index].label=', props.option[index].label);
  /* 
  const [value, setValue] = React.useState(init);
  const handleChange = (event) => {
    // console.debug(value);
        // console.debug(event.target.value);
        setValue(event.target.value);
    };
   */
  
  //styleName: Contents: Quantico/Body/16px: Regular;
  return (
    <TextField
      select
      fullWidth
      defaultValue={label}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: fontSize,
          color: v2.green50,
          fontFamily:'Quantico'
          // className: 'Contents-QuanticoBody16px-Regular', //not work //todo
          // className: 'Contents-QuanticoBody16px-Regular-White_50',
        },
        /* startAdornment: startAdorn && (
            <InputAdornment position="start">
                {startAdorn}
            </InputAdornment>
        ),
        endAdornment: endAdorn ? (
        <InputAdornment position="end">
            <div style={{ fontSize: '14px' }}>
                {endAdorn}
            </div>
        </InputAdornment> */
      }}
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        sx: {
          '.MuiSelect-icon': {
            color: v2.green50,
          },
        },
      }}
      // sx={{background: '#f00'}}
      {...props}>
      {props.option.map((option) => (
        <MenuItem key={option.label} value={option.label}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
