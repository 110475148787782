// -----official tools & third tools-------------------------------------------------------
import PropTypes from 'prop-types';

// -----custom tools-------------------------------------------------------
import csScss from "components/chart/ChartSection.module.scss";


//---------------------------------------------------------------------------
const ChartSection = ({ sectionTitle, sectionSubTitle, chartBlockTitle, chartBlockSubTitle, chartBlockSubTitleNumber, chartBlockSubTitle2, chartBlockSubTitleNumber2, yAxisLabel, mainChart, yAxisLabelBottom, paddingTopOfChild, ...props }) => {
  // -----variables-------------------------------------------------------

  // -----functions-------------------------------------------------------

  // -----render-------------------------------------------------------
  return (
    <div className={csScss.main}>
      {sectionTitle &&
        <div className={csScss.section_title}>{sectionTitle}</div>}

      {sectionSubTitle &&
        <div className={csScss.section_sub_title}>{sectionSubTitle}</div>}


      <div className={csScss.chart_block}>

        {(chartBlockTitle || chartBlockSubTitle) &&
          <div className={csScss.chart_block_title_row}>
            <div className={csScss.chart_block_title} style={{ width: chartBlockSubTitle ? "50%" : "100%" }}>
              {chartBlockTitle}
            </div>
            <div className={csScss.chart_block_sub_title}>
              {chartBlockSubTitle}&nbsp;<span className={csScss.number}>{chartBlockSubTitleNumber}</span>

              {chartBlockSubTitle2 && <> &nbsp;{` | ${chartBlockSubTitle2} `}&nbsp;<span className={csScss.number}>{chartBlockSubTitleNumber2}</span></>}
            </div>
          </div>
        } 
        <div className={csScss.main_chart} style={{ paddingTop: paddingTopOfChild }}>
          {mainChart}
        </div>

      </div>
    </div>
    )
};

ChartSection.propTypes = {
  sectionTitle: PropTypes.string,
  sectionSubTitle: PropTypes.string,
  chartBlockTitle: PropTypes.string,
  chartBlockSubTitle: PropTypes.string,
  chartBlockSubTitleNumber: PropTypes.number,
  chartBlockSubTitle2: PropTypes.string,
  chartBlockSubTitleNumber2: PropTypes.number,
  yAxisLabel: PropTypes.string,
  mainChart: PropTypes.element.isRequired,
  yAxisLabelBottom: PropTypes.string,
};

export default ChartSection;