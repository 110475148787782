import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import scss2 from 'layouts/LayoutPageV2.module.scss'
import scssBar2 from 'components/bread/BreadBarV2.module.scss';
import BreadCard from 'components/bread/BreadCard';
import paths from 'utils/network/apiPath';
import BreadcrumbV2 from "components/bread/BreadcrumbV2";
import BreadTextV2 from "components/bread/BreadTextV2";

//---------------------------------------------------------------------------
const pathShowBreadText = [
  paths.spp.tool.hotspot_result(),
  paths.spp.tool.ehp_tool_enzyme_page(),
  paths.spp.tool.ehp_tool_result(),
  paths.spp.peptide.id_target_protein_page(),
  paths.spp.peptide.hotspot_result(),
  paths.spp.tool.hotspot_result_precursor(),
]
//---------------------------------------------------------------------------
export default function LayoutPage2V2({ children, ...props }) {
  // console.log('Layout props', props);
  // console.log('Layout', props.bread);
  
  const [isShowBreadCard, setShowBreadCard] = useState(true)
  const [isShowBreadText, setShowBreadText] = useState(false)
  
  let location = useLocation();
  let pathname = location.pathname
  
  useEffect(() => {
    if (pathShowBreadText.includes(pathname)) {
      setShowBreadText(true)
      setShowBreadCard(false)
    }
  }, [location]);

  return (
    <div className={scss2.layout}>
      
      <div className={scssBar2.layout}>
        <div className={scssBar2.frame}>
          <BreadcrumbV2 bread={props.bread}/>
          
          {isShowBreadText
          ? <BreadTextV2 bread={props.bread} />
          : null}
          
          {isShowBreadCard
          ? <BreadCard card={props.card} output={props.output}></BreadCard>
          : null}
        </div>
      </div>
      
      <main className={scss2.frame_bottom}>{children}</main>
    </div>
  )
}
