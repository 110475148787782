import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBarV2';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import jsons, { spp } from 'utils/network/jsons';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';

//---------------------------------------------------------------------------
const jsonsResult = spp.tool.css_tool_result
const ToolCSSToolResultLv2 = ({ setInfo }) => {
  /* Table related */
  const [searchParams] = useSearchParams()
  const source = searchParams.get(paths.params.source)
  const keyword = searchParams.get(paths.params.keyword)
  const cls = searchParams.get(paths.params.class)
  const clsArr = cls ? cls.split(',') : ''
  
  const isSearchFragment = (keyword && keyword !== '')

  const clsName = cookie.getCookie(cookie.keys.tool.crsSelectCls)
  const sourceName = cookie.getCookie(cookie.keys.tool.crsSelectSourceLv2)
  // console.log('clsName:', clsName, ' sourceName:', sourceName);
  const breadText = `${sourceName} protein result`
  
  let jsons = {}
  if( cls === "multifunctionalpeptides" ) {
    jsons.tableHeadCell = jsonsResult.items_text_multi
    jsons.tableBodyCell = jsonsResult.items_multi
  } else {
    jsons.tableHeadCell = jsonsResult.items_text
    jsons.tableBodyCell = jsonsResult.items
  }
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let texts = {
    bread: breadData.create('Tools', breadText, bread3rd, 'Result'),

    searchHint: 'Search by SPP ID, AA sequence, Peptide name, AA length, Precursor protein, Pubdate',
    tableHeadCell: jsons.tableHeadCell,
    tableBodyCell: jsons.tableBodyCell,
    
    multi: {
      tableHeadCell: jsonsResult.items_text_multi,
      tableBodyCell: jsonsResult.items_multi,
    },
  }

  //---------------------------------------------------------------------------
  /* Bar chart related */
  let barChartWords = {
    title: "The sequence counts of selected protein and peptide(s)",
    text: "",
    x: 'Peptide Classification',
    y: 'Count',
  }
  
  // const [barChartTotal, setBarChartTotal] = useState(0);
  const [barChartLabels, setBarChartLabels] = useState([]);
  const [barChartValue, setBarChartValue] = useState([]);
  const bgColors = [
    "#0dddcd", "#1CFCBE", "#29FCCF", "#36FCE0", "#43F7F2",
    "#50EDF3", "#5DD1F2", "#6AB5F1", "#7799F1", "#856DF0"
  ]
  const bgHoverColors = [
    "#2FEBD3", "#2DFFE1", "#3AFFF2", "#47FFF3", "#54FFF5",
    "#61F8F6", "#6EDAF5", "#7BBEF4", "#88ACF4", "#978FF3"
  ]
  const barChartData = chartProp.data.carate(
    "",
    "",
    [''],
    barChartLabels,
    barChartValue,
  )
  const [arrPeptideCls, setPeptideCls] = useState([])
  const barChartOptions = chartProp.options.axisTitleXY(barChartWords.x, barChartWords.y,true)
  Object.assign(barChartOptions, {
    onClick: (event, elements) => {
      if (elements.length === 0)
        return;
      
      // console.log('elements', elements);
      const index = elements[0].index
      const cls = isSearchFragment ? arrPeptideCls[index] : clsArr[index]
      // const name = clsName[index]
      // console.log('index=', index, 'cls=', cls, 'name=', name);
      cookie.setCookie(cookie.keys.tool.crsSelectSourceLv3, sourceName)
      if( isSearchFragment )
        navigate(paths.spp.tool.css_tool_result_lv3(source, {cls: cls, keyword: keyword}));
      else
        navigate(paths.spp.tool.css_tool_result_lv3(source, {cls: cls}));
  }})
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const jsonChart = spp.tool.css_tool_result_lv2_bar_chart
  const getBarChartData = () => {
    let input = jsonChart.input(source, clsArr)
    if( isSearchFragment )
      input = jsonChart.input_fragment(source, keyword)
    const config = apiConfig.tool.css_tool_result_lv2_bar_chart(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        // let output = result.data
        // setBarChartTotal(output[jsonChart.output[0]])

        let data = result.data[spp.common.output.items]
        // console.log('data',data);
        setBarChartLabels(data.map(item => item[jsonChart.items[3]])) //"display_name"
        setBarChartValue(data.map(item => item[jsonChart.items[5]])) //"count"
        
        let cls = data.map(item => item[jsonChart.items[2]]) //"table_name",
        setPeptideCls(cls)
        // console.log('cls', cls);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  useEffect(() => {
    getBarChartData()
  }, []);

  return (
    <>
      <SearchResultLayout
        setInfo={setInfo}
        texts={texts}
        configSearch={apiConfig.tool.css_tool_result} //86
        jsonInput={isSearchFragment ? jsonsResult.input_fragment : jsonsResult.input}
        inputProp={[[source], (isSearchFragment ? keyword : clsArr)]}
        jsonInputKeyword={isSearchFragment ? jsonsResult.input_fragment_keyword : jsonsResult.input_keyword}
        isSearchFragment={isSearchFragment}
        barChart={<ChartSection
          chartBlockTitle={barChartWords.title}
          chartBlockSubTitle={barChartWords.text}
          // chartBlockSubTitleNumber={barChartTotal}
          mainChart={
            <BarChart
              data={barChartData}
              options={barChartOptions}
              yAxisLabel={barChartWords.y}
              needHoverStyle={true}
              showTableLabel={false} />
          } />}
      />
    </>
  )
}
export default ToolCSSToolResultLv2