import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Input, TableHead, TableRow } from '@mui/material';

import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import v from 'assets/scss/_variables.scss';
import { NoneDiv } from 'components/GreenDiv';
import { TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import paths from "utils/network/apiPath";
import StyledTableCell from 'components/table/StyledTableCell';
import { MuiTableContainer } from "components/table/MuiTable";
import { images } from 'utils/imgs';

//---------------------------------------------------------------------------
const texts = {
  title: 'Descriptors of ISA-ECI  for 20 coded amino acids.',

  additional_info: 'ᵃ The isotropic surface area (ISA) approximates the hydrophobic character of the side chain substituent.ᵇ The electronic charge index (ECI) is a measure of the charge concentration of the amino acid. Each residue was described by a combination of ISA and ECI descriptors.',

  references: {
    title: 'References',
    context: '[1] E. R. Collantes, W. J. Dunn, 3rd, Amino acid side chain descriptors for quantitative structure-activity relationship studies of peptide analogues, J. Med. Chem., 38 (1995) 2705-2713.'
  },
  table: {
    text: 'Descriptors of ISA-ECI  for 20 coded amino acids.',
    title: [
      'AA', 'Abbr.', 'ISAᵃ', 'ECIᵇ'
    ]
  }
}

// class itemData {
//   static create(amino, three, single, molecular, isoelectric, hydrophilicity) {
//     return { amino, three, single, molecular, isoelectric, hydrophilicity }
//   }
//   static output = ['amino', 'three', 'single', 'molecular', 'isoelectric', 'hydrophilicity']
// }
class itemData {
  static create(aa, abbr, isa, eic) {
    return { aa, abbr, isa, eic}
  }
  static output = ['aa', 'abbr', 'isa', 'eic']
}

const items = [ 
itemData.create('Ala', 'A', '62.90', '0.05'),
itemData.create('Arg', 'R', '52.98', '1.69'),
itemData.create('Asn', 'N', '17.87', '1.31'),
itemData.create('Asp', 'D', '18.46', '1.25'),
itemData.create('Cys', 'C', '78.51', '0.15'),
itemData.create('Gln', 'Q', '19.53', '1.36'),
itemData.create('Glu', 'E', '30.19', '1.31'),
itemData.create('Gly', 'G', '19.93', '0.02'),
itemData.create('His', 'H', '87.38', '0.56'),
itemData.create('Ile', 'I', '149.77', '0.09'),
itemData.create('Leu', 'L', '154.35', '0.10'),
itemData.create('Lys', 'K', '102.78', '0.53'),
itemData.create('Met', 'M', '132.22', '0.34'),
itemData.create('Phe', 'F', '189.42', '0.14'),
itemData.create('Pro', 'P', '122.35', '0.16'),
itemData.create('Ser', 'S', '19.75', '0.56'),
itemData.create('Thr', 'T', '59.44', '0.65'),
itemData.create('Trp', 'W', '179.16', '1.08'),
itemData.create('Tyr', 'Y', '132.16', '0.72'),
itemData.create('Val', 'V', '120.91', '0.07')
]

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)
const MuiTableHeadCell = (props) => (
  <MuiTableHead>
    <TableRow>
      <StyledTableCell style={{ color: v.white }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiTableFooterCell = (props) => (
  <MuiTableHead className="bgc-grey100">
    <StyledTableCell style={{ color: v.white,backgroundColor:v.grey100 }} {...props}>
      {props.children}
    </StyledTableCell>
  </MuiTableHead>
)

const rowChildren = (item, index) => {
  return (
    <TableRow2Body key={index}>
      {itemData.output.map((output, count) => (
        <StyledTableCell key={count}>{item[output]}</StyledTableCell>
      ))}
    </TableRow2Body>
  )
}

//---------------------------------------------------------------------------
const ISAECI  = () => {
  return (
    <NoneDiv style={{ gap: 20 }}>
      {/* <h1>{texts.title}</h1> */}
      <img  src={images._04ToolAASD['04ISA-ECI.png']}alt="ISAECI" />

      {/* <MuiTableContainer>
        <MuiTableHeadCell colSpan={texts.table.title.length}>{texts.input}</MuiTableHeadCell>
        <TableBody>
          <StyledTableCell colSpan={texts.table.title.length}>
            <h3>{texts.searchHint}</h3>
            <Input className='b2-grey100'
              sx={{ m: 0, flex: 1, width: '97%' }}
              placeholder={texts.searchHint}
              value={keyword}
              onChange={(event) => { handleChangeSearch(event.target.value) }}
              onKeyDown={handleKeyDown}
              error={isSearchError ? true : false}
            />
            <IconButton type="button" sx={{ padding: '0px' }} aria-label="search" onClick={handleClickSearch}>
              <SearchIcon />
            </IconButton>
          </StyledTableCell>
        </TableBody>
      </MuiTableContainer>
       */}
      {/* <MuiTableContainer size="small">
        <TableHeadRow className="bgc-dark-green">
          {texts.table.title.map((title, index) => (
            <StyledTableCell key={index} style={{ color: v.white }}>{title}</StyledTableCell>
          ))}
        </TableHeadRow>
        <TableBody>
          {items.map((item, index) => (
            rowChildren(item, index)
          ))}
        </TableBody>
        <MuiTableHeadCell colSpan={texts.table.title.length} align='flex-end'>{texts.additional_info}</MuiTableHeadCell>

        <MuiTableFooterCell colSpan={texts.table.title.length} align='flex-end'>{texts.references.title}:{texts.references.context}</MuiTableFooterCell>
      </MuiTableContainer> */}
    </NoneDiv>
  )
}
export default ISAECI