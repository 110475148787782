import { useNavigate } from 'react-router-dom';

import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import { NoneDiv } from 'components/GreenDiv';
import imgs from 'utils/imgs';
import Img from 'components/tag/Img';
//---------------------------------------------------------------------------
// http://localhost:3000/tool_ehp_et
// path: paths.spp.tool.ehp_tool_enzyme_table(),

//---------------------------------------------------------------------------
const ToolEHPToolEnzymeTable = () => {
  return (
    <NoneDiv style={{ gap: 20 }}>
      <Img imgName={imgs.spp.tool.ehp_tool_enzyme_table} />
    </NoneDiv>
  )
}
export default ToolEHPToolEnzymeTable