/* 
Line Chart | react-chartjs-2
https://react-chartjs-2.js.org/examples/line-chart
Line Chart | Chart.js
https://www.chartjs.org/docs/latest/charts/line.html
*/
import { Line } from 'react-chartjs-2';

import Text from 'components/tag/Text';

//---------------------------------------------------------------------------
const LineChart = ({ data, options, ...props }) => {
  let _options = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        ticks: {
          color: 'white'  // X 軸文字顏色
        }
      },
      y: {
        ticks: {
          color: 'white'  // Y 軸文字顏色
        }
      }
    },
    plugins: {
      legend: {
        display: true, //hide labels
        labels: {
          color: 'white',
        }
      },
    },
    borderWidth: 1,
  }

  console.log('00',_options)
  if (options !== undefined) {
    Object.assign(_options, options)
  }

  return (
    <div style={{ width: '90%' }}>
      {/* <Text center><h2>{data.title}</h2></Text> */}
      {/* <Text center style={{ color: 'gray' }}><h4>{data.text}</h4></Text> */}
      <Line
        data={data}
        options={_options}
        {...props} />
    </div>
  )
}
export default LineChart