// https://github.com/log4js-node/log4js-node/issues/817
// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, "isObject", {
  enumerable: false, // this line is optional as false is the default value
  value: function (obj) {
    if (Object.prototype.toString.call(obj) === "[object Object]") {
      return true;
    }
    return false;
  },
});


export function sortByKey(objRow) {
  return Object.keys(objRow)
    .sort((a, b) => a - b) //for number e.g. [1,10,2,30,5] => [1,2,5,10,30]
    .reduce((obj, key) => {
      obj[key] = objRow[key];
      return obj;
    }, {});
}

export function getKeyByValue(object, value) {
  const keys = Object.keys(object);
  const foundKey = keys.find(key => object[key] === value);
  // console.log('keys', keys)
  return foundKey || null; // 如果没有找到匹配的值，返回null或其他适当的值
}

// FormData
export function appendFromObjectKayValue(formData, object) {
  for ( var key in object ) {
    formData.append(key, object[key]);
  }
}