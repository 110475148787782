import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Input, Radio, RadioGroup, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledTableCell from 'components/table/StyledTableCell';

import 'assets/scss/indexV2.scss';
import 'assets/scss/temp.scss';
import scss from './PeptideAdvancedSearch.module.scss';
import scssTable from 'components/table/TableV2.module.scss'
import scssButton from 'components/button/Buttons.module.scss'

import LoadingAnime from 'components/LoadingAnime';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import { breadData } from 'components/bread/BreadBarV2';
import { MuiTableCell, TableRow2Body, MuiTableContainer } from 'components/table/MuiTableV2';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { getFullPath, isEmptyObjOrArray } from 'utils/general';
import Text from 'components/tag/Text';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { linkData } from 'utils/links';
import MuiButton from 'components/mui/MuiButton';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GreenButton from 'components/button/GreenButton';

//---------------------------------------------------------------------------
// path: paths.spp.peptide.advanced_search(),
// http://localhost:3000/peptide_adv
const texts = {
  bread: breadData.create('Peptide', 'Advanced search for bioactive peptides', 'Advanced search'),
  title: 'Advanced search for bioactive peptides',
  step: [
    'Step 01',
    'Step 02',
    'Step 03'
  ],

  step1_1: [
    'Bioactive peptide selection',
    'Search filter selection',
    'Enter search value'
  ],

  buttons: [
    'Check All',
    'Uncheck All',
    'Reverse Check',
  ],

  filter: [
    'SPP ID',
    'Fixed length',
    'Range length',
    'Specific sequence',
    'Blast sequence',
  ],

  searchHint: 'Search value',

  table: {
    results: 'Search results:',
    title: spp.peptide.search.advanced.output_text,
    link_header: function (value, name) {
      return `Search ${value} by ${name} filter`
    },
  },
}

const filters = {
  peptide: [ //6 peptides_advanced_search Remark(J10)
    "SPP_ID",
    "Peptide_length",
    "Range_length",
    "Peptide_sequence",
    "Blast_sequence",
  ],
}

function indexData(start, end) {
  return { start, end }
}

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)

//---------------------------------------------------------------------------
const PeptideAdvanceSearch = ({ setInfo }) => {
  const userCls = cookie.getCookie(cookie.keys.peptide.peptideAdvUserCls)
  const userFlt = cookie.getCookie(cookie.keys.peptide.peptideAdvUserFilter)
  const userFltLbl = cookie.getCookie(cookie.keys.peptide.peptideAdvUserFilterLabel)
  const userKeyword = cookie.getCookie(cookie.keys.peptide.peptideAdvUserKeyword)
  // console.log('userCls', userCls);
  // console.log('userFil', userFlt);
  // console.log('userKeyword', userKeyword);
  const userClsArr = userCls ? userCls.split(',') : ''
  // console.log('userClsArr=', userClsArr);

  let location = useLocation()
  let bread = linkData('Advanced search', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log(bread, json3rd);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  //---------------------------------------------------------------------------
  const [outputList, setOutputList] = useState({})
  const [itemsList, setItemsList] = useState([])
  const [stateList, setStateList] = useState(axiosState.init())
  const navigate = useNavigate()
  const jsonList = spp.peptide.list
  const apiList = () => {
    const config = apiConfig.peptide.list()
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutputList(output)
        // console.log('output', output)

        let item = result.data[spp.common.output.items]
        if (!isEmptyObjOrArray(item))
          item = item.map((item) => ({ ...item, isCheck: true, isDisable: false }))
        setItemsList(item)
        // console.log('items', item)

        setClassifyCheckbox(item)

        let selectClassName = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName) //output:ACE-inhibitory peptides
        selectClassName = selectClassName.slice(0, -1); //output:ACE-inhibitory peptide[s]

        // const peptideDisplayNameList = item.map(item => item.classification)
        // let num = peptideDisplayNameList.indexOf(`${selectClassName}s`)
        // let check = Array(item.length)
        // if (num !== -1) {
        //   check.fill(false)
        //   check[num] = true
        // setChecked(check)
        // }
        // else {
        // check.fill(true)
        // setChecked(check)
        // }

        if (userClsArr.length > 0) {
          handleClickOption(texts.buttons[1]) //'Uncheck All',
          userClsArr.forEach((id) => {
            handleChangeClassify(id)
          })
        }
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  //---------------------------------------------------------------------------
  // Step 01
  const optionSize = 3
  const [classifyIndex, setClassifyIndex] = useState([])
  function setClassifyCheckbox(item) {
    if (item !== undefined) {
      let index = []
      for (const i of Array(Math.ceil(item.length / optionSize)).keys())
        index.push(indexData(i * optionSize, (i * optionSize) + optionSize - 1))
      setClassifyIndex(index)
    }
  }

  // const [classifyChecked, setChecked] = useState([])
  const handleChangeClassify = (col) => {
    let bCheck
    setItemsList(prevItems => prevItems.map((item) => {
      if (item[jsonList.items[1]] === col) { //"classification_col",
        bCheck = !item.isCheck
        return { ...item, isCheck: bCheck };
      }
      return item
    })
    )
  }
  function getClassifyList(itemsList) {
    if (itemsList !== undefined) {
      let list = itemsList.filter(item => item.isCheck)
      if (list.length > 0) {
        let listCol = list.map(item => item[jsonList.items[1]]) //"classification_col",
        // console.log('listCol', listCol);
        return listCol
      }
    }
    return []
  }

  const selectOption = (textButton, index) => {
    // console.log(textButton);
    return (
      <Button key={index} className={scssButton.button} style={{margin:'20px'}}
        onClick={() => handleClickOption(textButton)}>
        {textButton}</Button>
    )
  }
  const handleClickOption = (value) => {
    switch (value) {
      case texts.buttons[0]: //'Check All',
        setItemsList(prevItems => prevItems.map((item) => ({
          ...item,
          isCheck: true,
          isDisable: false,
        })))
        break
      case texts.buttons[1]: //'Uncheck All',
        setItemsList(prevItems => prevItems.map((item) => ({
          ...item,
          isCheck: false,
          isDisable: false,
        })))
        break
      case texts.buttons[2]: //'Reverse Check',
        setItemsList(prevItems => prevItems.map((item) => ({
          ...item,
          isCheck: !item.isCheck,
          isDisable: false,
        })))
        break
      default:
    }
  }

  //---------------------------------------------------------------------------
  // Step 02
  const [filter, setFilter] = useState(userFlt ? userFlt : filters.peptide[0])
  const [filterLabel, setFilterLabel] = useState(userFltLbl ? userFltLbl : texts.filter[0])
  const handleChangeFilter = (value, label) => {
    // console.log('value', value, 'label', label);
    setFilter(value);
    setFilterLabel(label)
  }

  //---------------------------------------------------------------------------
  // Step 03
  const jsonAdv = spp.peptide.search.advanced
  const [keyword, setKeyword] = useState(userKeyword)
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
      handleClickSearch()
  }

  const handleClickSearch = () => {
    let clsId = getClassifyList(itemsList)
    // console.log('clsId', clsId);
    // console.log('keyword', keyword);

    let clsLength = clsId.length
    if (keyword !== '' && clsLength > 0) {
      let clsIdStr = clsId.toString()
      // console.log('clsIdStr', clsIdStr);

      cookie.setCookie(cookie.keys.peptide.peptideAdvUserCls, clsIdStr)
      cookie.setCookie(cookie.keys.peptide.peptideAdvUserFilter, filter)
      cookie.setCookie(cookie.keys.peptide.peptideAdvUserFilterLabel, filterLabel)
      cookie.setCookie(cookie.keys.peptide.peptideAdvUserKeyword, keyword)

      setInput(jsonAdv.input(clsId, filter, keyword))
      setSearch(true)
      setState(axiosState.init())
    } else {
      if (clsLength === 0 && keyword !== '')
        setInfo(snackInfo.openError("Please finish step1"))
      else if (clsLength > 0 && keyword === '')
        setInfo(snackInfo.openError("Please finish step3"))
      else if (clsLength === 0 && keyword === '')
        setInfo(snackInfo.openError("Please finish step1 & step3"))
    }
  }

  //---------------------------------------------------------------------------
  // Search results
  const [input, setInput] = useState(jsonAdv.input(getClassifyList(itemsList), filter, keyword))
  const [value, setValue] = useState()
  const [select, setSelect] = useState()
  const [output, setOutput] = useState({})
  const [items, setItems] = useState([])
  const [isSearching, setSearch] = useState(false)
  const [state, setState] = useState(axiosState.init())
  const apiResult = () => {
    const config = apiConfig.peptide.advanced_search(input)
    // console.log(input);
    // console.log(config);

    setValue(input.filterValue)
    setSelect(input.filterSelection)

    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutput(output)

        let item = result.data[spp.common.output.items]
        setItems(item)
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const rowChildren = (item, index, navigate, path) => {
    return (
      <TableRow2Body key={index} className={scssTable.body_row_interactive}
        onClick={() => handleClickResult(item, path)} style={{ cursor: item.results !== 0 ? "pointer" : "auto" }}>
        <StyledTableCell component="th" scope="row" >{index + 1}</StyledTableCell>
        <StyledTableCell style={{color: 'var(--green-alpha-alpha-75)', textDecoration:'underline' }}>{item.display_name} peptides</StyledTableCell>
        <StyledTableCell className={item.results !== 0 ? "link" : ""}>
          <Text onClick={() => handleClickResult(item, path)}>
            {item.results}</Text>
        </StyledTableCell>
      </TableRow2Body>)
  }
  const handleClickResult = (item, path) => {
    // console.log('item', item);
    let breadText = item.display_name
    let bread = linkData(breadText, path)
    let json4th = JSON.stringify(bread)
    // console.log(bread, json4th);
    cookie.setCookie(cookie.keys.protein.bread4th, json4th)

    // let title = `${item.display_name} peptides`
    let title = texts.table.link_header(keyword, filterLabel)
    cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, title)
    navigate(path)
  }

  //---------------------------------------------------------------------------
  useEffect(() => {
    if (axiosState.keepRest(stateList))
      apiList()
    if (axiosState.keepRest(state) && isSearching === true)
      apiResult()
    // eslint-disable-next-line
  }, [state])

  //---------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.text}>
        {/* Step 01 */}
        <ToolSingleBlock stepText={texts.step[0]} title={texts.step1_1[0]} 

        mainBlockContent={<>
          <TableBody style={{border:'none'}}>
            {classifyIndex.map((value, count) => {
              // console.log("01",classifyIndex)
              return (
                <TableRow2Body key={count} style={{ border: 0 }}>
                  {itemsList.slice(value.start, value.end + 1).map((item, index) => {
                    let idx = (value.start + index)
                    return (<StyledTableCell key={`cell-${count}-${index}`} sx={index === 0 ? { padding: 0, paddingLeft: 2 } : { padding: 0 }}>
                      <FormControlLabel
                        key={`frm-${item.classification}`}
                        sx={{
                          whiteSpace: 'pre-line',
                          '& .MuiFormControlLabel-label': {
                            fontFamily: 'Quantico',
                            fontSize: '16px'
                          },
                          '& .Mui-checked + .MuiFormControlLabel-label': {
                            fontWeight: '600',
                          }
                        }}
                        className=''
                        control={
                          <MuiCheckbox
                            key={item.classification_col}
                            name={item.classification}
                            value={idx}
                            checked={item.isCheck}
                            // disabled={item.isDisable}
                            onChange={() => handleChangeClassify(item.classification_col)}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                        label={`(${idx + 1}) ${(item.classification).trimClassifyName()}`}
                      />
                    </StyledTableCell>)

                  }
                  )}
                </TableRow2Body>
              )
            })}
          </TableBody></>}

          anotherBlockContent={
            <TableRow>
              <MuiTableCell sx={{ border: 0, padding: 0 }}>
                {texts.buttons.map((item, index) => selectOption(item, index))}
              </MuiTableCell>
            </TableRow>}
        />
        {/* Step 02 */}
        <ToolSingleBlock stepText={texts.step[1]} title={texts.step1_1[1]} mainBlockContent={<>
          <RadioGroup row name="radio-buttons-group"
            value={filter}>
            {texts.filter.map((item, index) => (
              <FormControlLabel sx={{
                color: 'var(--white-white-75)', whiteSpace: 'pre-line',
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'Quantico',
                  fontSize: '16px'
                },
                '& .Mui-checked + .MuiFormControlLabel-label': {
                  fontWeight: '600',
                }
              }} key={item} value={filters.peptide[index]} index={index} label={item}
                onChange={(event) => handleChangeFilter(event.target.value, item)}
                control={<Radio
                  sx={{
                    color: 'var(--white-white-50)',
                    '&.Mui-checked': { color: 'var(--green-alpha-alpha-40)', },
                  }}
                />}
              />
            ))}
          </RadioGroup>
        </>} />

      {/* Step 03 */}
      <ToolSingleBlock stepText={texts.step[2]} title={texts.step1_1[2]} mainBlockContent={
        <>
          <div className={scss.row_select}>
            <Input className='input-rounded'
              sx={{ m: 0, flex: 1, width: '97%', color: 'white', }}
              placeholder={texts.searchHint}
              value={keyword}
              onChange={(event) => { handleChangeSearch(event.target.value) }}
              onKeyDown={handleKeyDown}
            />
            <Button className={scssButton.button}
              onClick={handleClickSearch}>Search</Button>
          </div>
        </>} />

        {isSearching ?
          (state.isLoading ?
            <LoadingAnime />
            : 
              <MuiTableContainer>
              <Table ><StyledTableCell style={{color:'white'}}>{texts.table.results + ' ' + output.total}</StyledTableCell></Table> 
                <Table size="small" aria-label="a dense table">
                  <MuiTableHead>
                    {texts.table.title.map((title, index) => (
                      <StyledTableCell key={title} style={{ color: 'var(--white-white-75)', borderRight: '1px solid var(--green-alpha-alpha-16)' }}>{title}</StyledTableCell>
                    ))}
                  </MuiTableHead>
                  <TableBody className={scssTable.table_borderless}>
                    {items.map((item, index) => {
                      return rowChildren(item, index, navigate, item.results !== 0
                        ? paths.spp.peptide.class_advanced_search(item.classification, select, value)
                        : "")
                    })}
                  </TableBody>
                </Table>
              </MuiTableContainer>
          )
          : null}
          
    </LayoutPageV2>
  )
}
export default PeptideAdvanceSearch