import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Input, TableHead, TableRow } from '@mui/material';

import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import v from 'assets/scss/_variables.scss';
import { NoneDiv } from 'components/GreenDiv';
import { TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import paths from "utils/network/apiPath";
import StyledTableCell from 'components/table/StyledTableCell';
import { MuiTableContainer } from "components/table/MuiTable";
import { images } from 'utils/imgs';

//---------------------------------------------------------------------------
const texts = {
  title: 'Descriptors of NNAAIndex for 20 coded amino acids.',
  additional_info: 'ᵃ The NNAAIndex scales characterize a total of 155 physiochemical properties of 22 natural and 593 non-natural amino acids, followed by clustering the structural matrix into 6 representative property patterns by factor analysis. The six factors are geometric characteristics, H-bond, connectivity, accessible surface area, integy moments index, and volume and shape, respectivel',
  references: {
    title: 'References',
    context: '[1] G. Liang, Y. Liu, B. Shi, J. Zhao, J. Zheng, An index for characterization of natural and non-natural amino acids for peptidomimetics, PLoS One, 8 (2013) e67844.'
  },
  table: {
    text: 'Descriptors of NNAAIndex for 20 coded amino acids.',
    title: [
      'AA', 'Abbr.', 'Factor1ᵃ', 'Factor2', 'Factor3', 'Factor4', 'Factor5','Factor6'
    ]
  }
}

class itemData {
  static create(aa, abbr, factor1, factor2, factor3, factor4, factor5, factor6) {
    return { aa, abbr, factor1, factor2, factor3, factor4, factor5, factor6}
  }
  static output = ['aa', 'abbr', 'factor1', 'factor2', 'factor3', 'factor4', 'factor5', 'factor6']
}

const items = [ 
itemData.create('Ala', 'A', '-1.319', '-0.418', '-0.571', '0.490', '-0.165', '-2.024'),
itemData.create('Arg', 'R', '-0.245', '0.161', '-1.192', '0.117', '1.278', '0.349'),
itemData.create('Asn', 'N', '-0.913', '1.796', '-0.880', '0.061', '-0.775', '-0.858'),
itemData.create('Asp', 'D', '-1.183', '0.705', '-0.603', '0.222', '-0.748', '-1.376'),
itemData.create('Cys', 'C', '-1.068', '0.023', '-0.808', '0.537', '-0.113', '-1.055'),
itemData.create('Gln', 'Q', '-0.721', '1.887', '-0.887', '-0.030', '-0.251', '-0.281'),
itemData.create('Glu', 'E', '-0.726', '1.814', '-0.870', '0.095', '-0.671', '-0.005'),
itemData.create('Gly', 'G', '-1.515', '-0.272', '-0.009', '0.606', '-0.496', '-1.792'),
itemData.create('His', 'H', '-0.654', '1.027', '0.967', '-0.020', '0.166', '-0.304'),
itemData.create('Ile', 'I', '-0.705', '-0.380', '-1.537', '0.055', '0.516', '-1.071'),
itemData.create('Leu', 'L', '-0.694', '-0.336', '-1.357', '0.061', '0.774', '-0.807'),
itemData.create('Lys', 'K', '-0.523', '0.063', '-0.949', '-0.104', '0.427', '0.302'),
itemData.create('Met', 'M', '-0.657', '-0.310', '-0.988', '0.074', '1.316', '0.321'),
itemData.create('Phe', 'F', '-0.427', '-0.150', '0.783', '-0.304', '1.049', '0.130'),
itemData.create('Pro', 'P', '-1.004', '-1.165', '0.941', '0.285', '1.026', '-1.492'),
itemData.create('Ser', 'S', '-1.210', '1.428', '-0.631', '0.342', '-1.030', '-1.424'),
itemData.create('Thr', 'T', '-1.024', '1.143', '-1.107', '0.201', '-0.636', '-1.408'),
itemData.create('Trp', 'W', '0.072', '-0.027', '1.454', '-0.444', '0.767', '0.071'),
itemData.create('Tyr', 'Y', '-0.202', '-0.061', '0.638', '-0.236', '-0.101', '0.459'),
itemData.create('Val', 'V', '-0.924', '-0.464', '-1.305', '0.165', '0.450', '-1.416')
]

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)
const MuiTableHeadCell = (props) => (
  <MuiTableHead>
    <TableRow>
      <StyledTableCell style={{ color: v.white }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiTableFooterCell = (props) => (
  <MuiTableHead className="bgc-grey100">
    <StyledTableCell style={{ color: v.white,backgroundColor:v.grey100 }} {...props}>
      {props.children}
    </StyledTableCell>
  </MuiTableHead>
)

const rowChildren = (item, index) => {
  return (
    <TableRow2Body key={index}>
      {itemData.output.map((output, count) => (
        <StyledTableCell key={count}>{item[output]}</StyledTableCell>
      ))}
    </TableRow2Body>
  )
}

//---------------------------------------------------------------------------
const NNAAIndex = () => {
  return (
    <NoneDiv style={{ gap: 20 }}>
      <img  src={images._04ToolAASD['03NNAAIndex.png']}alt="NNAAIndex" />

      {/* <h1>{texts.title}</h1> */}

      {/* <MuiTableContainer>
        <MuiTableHeadCell colSpan={texts.table.title.length}>{texts.input}</MuiTableHeadCell>
        <TableBody>
          <StyledTableCell colSpan={texts.table.title.length}>
            <h3>{texts.searchHint}</h3>
            <Input className='b2-grey100'
              sx={{ m: 0, flex: 1, width: '97%' }}
              placeholder={texts.searchHint}
              value={keyword}
              onChange={(event) => { handleChangeSearch(event.target.value) }}
              onKeyDown={handleKeyDown}
              error={isSearchError ? true : false}
            />
            <IconButton type="button" sx={{ padding: '0px' }} aria-label="search" onClick={handleClickSearch}>
              <SearchIcon />
            </IconButton>
          </StyledTableCell>
        </TableBody>
      </MuiTableContainer>
       */}
      {/* <MuiTableContainer size="small">
        <TableHeadRow className="bgc-dark-green">
          {texts.table.title.map((title, index) => (
            <StyledTableCell key={index} style={{ color: v.white }}>{title}</StyledTableCell>
          ))}
        </TableHeadRow>
        <TableBody>
          {items.map((item, index) => (
            rowChildren(item, index)
          ))}
        </TableBody>
        <MuiTableHeadCell colSpan={texts.table.title.length} align='flex-end'>{texts.additional_info}</MuiTableHeadCell>

        <MuiTableFooterCell colSpan={texts.table.title.length} align='flex-end'>{texts.references.title}:{texts.references.context}</MuiTableFooterCell>
      </MuiTableContainer> */}
    </NoneDiv>
  )
}
export default NNAAIndex