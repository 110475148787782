// import React from 'react'
import { Link } from "react-router-dom"
import Text from "./Text"

//---------------------------------------------------------------------------
export const TagDiv = (props) => {
  const {html, ...other} = props
  return (<div dangerouslySetInnerHTML={{ __html: html }} {...other} />)
}

export const DivLink = (props) => {
  const {link, text, ...other} = props
  const isUrl = (link !== undefined) && (link.url !== null) && (link.url !== '')
  if(isUrl)
    return (
      <Link to={link.url}>
        <div {...other}>
          <Text className={text}>
            {link.text}
          </Text>
        </div>
      </Link>)
  else
    return (
      <div {...other}>
        <Text className={text}>
          {link ? link.text : props.children}
        </Text>
      </div>)
}