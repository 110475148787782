import { Checkbox } from "@mui/material";

//---------------------------------------------------------------------------
const MuiCheckbox = (props) => (
  <Checkbox
    sx={{
      size:'small',
      color: 'var(--white-white-50)',
      '&.Mui-checked': { color: 'var(--green-alpha-alpha-40)' },
      '&.Mui-disabled':{ color: 'var(--white-white-20)'},
    }}
    {...props} />
)

export default MuiCheckbox;