// -----official tools & third tools-------------------------------------------------------
import { Button, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { breadData } from 'components/bread/BreadBarV2';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import v2 from 'assets/scss/_variablesV2.scss';
import scss from './StatisticProtein.module.scss';
import SnackBar, { emptyResult, snackInfo, snackType } from "components/SnackBar";
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import PercentageBar from 'components/peptide/PercentageBar';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, determineHasEmptyListInTwoLevelObj, isEmptyObjOrArray } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from "utils/network/jsons";
import PeptideXYTable from 'components/tool/PeptideXYTable';
import { MuiTableContainer } from "components/table/MuiTable";
import LayoutPageV2 from 'layouts/LayoutPageV2';
import LoadingAnime from 'components/LoadingAnime';
import StatCard from 'components/statistic/StatCard';
import GreenButton from 'components/button/GreenButton';

// ---------------------------------------------------------------------------
// http://localhost:3000/stat_protein

const jsonProtein = spp.statistic.portein
const jsonChart = spp.statistic.portein_chart

export const wordsStat = {
    source: {
      title: 'The number of proteins contained in the five sources reviewed by UniProt',
      text: ``,
      x: 'Source',
      y: 'Cumulative count',
    },
    classification: {
      title: 'The count of unique sequences of bioactive peptides encoded in the five sources',
      text: ``,
      x: 'Source',
      y: 'Peptide count',
    },
    fragment: {
      title: 'The count of bioactive peptides accumulated in the five sources',
      text: '',
      x: 'Source',
      y: 'Cumulative count',
    },
    average: {
      title: 'The average count of bioactive peptides accumulated in the five sources',
      text: '',
      x: 'Source',
      y: 'Average count',
    },
  }

export const chartsStat = {
  chartTitle: '',
  chartLabelX: 'Source',
  chartLabelY: 'Count',
  methodBtnList: [
    'Protein',
    'Peptide',
    'Average',
  ],
  // "Milk", "Plant", "Animal", "Marine", "Microorganism"
  colors: [
    'rgba(243, 18, 96, 1)', 
    'rgba(241, 116, 214, 1)', 
    'rgba(2, 164, 255, 1)', 
    'rgba(0, 111, 238, 1)', 
    'rgba(120, 40, 200, 1)'],
  
  getArrDatasets: function(arrayH) {
    let arrDatasets = []
    this.methodBtnList.forEach((value, index) => {
      if(Array.isArray(arrayH)) {
        let arrCount = []
        if(index === 0)
          arrCount = arrayH.map((item) => item["source_total"])
        else if(index === 1) 
          arrCount = arrayH.map((item) => item["peptide_total"])
        else if(index === 2) 
          arrCount = arrayH.map((item) => item["avg_count"])
        // console.log(value, 'arrCount', arrCount);
        arrDatasets.push(arrCount)
      } else {
        arrDatasets.push([])
      }
    })
    return arrDatasets
  },
}

export default function StatisticProtein({ setInfo }) {
  // -----variables-------------------------------------------------------
  // const title = "WWWWWWWWW_WWWWWWWWW2WWWWWWWWW3WWWWWWWWW4WWWWWWWWW5WWWWWWWWW6"; //test
  const [totalProteins, setTotalProteins] = useState(70372)
  const [totalPeptides, setTotalPeptides] = useState(2751)
  const [totalSpecies, setTotalSpecies] = useState(88)
  const title = `Sequence coding analysis of food-derived bioactive peptides according to ${totalSpecies} species`;
  const texts = {
    bread: breadData.create('Statistics', title, 'Protein ')
  }
  
  let words = wordsStat
  words.source.text = `Total proteins: ${totalProteins} results`;
  words.classification.text = `Total peptides: ${totalPeptides} results`;
  
  //------------------------------------------------------------------------------
  const charts = {
    ...chartsStat,
    title: [
      `The number of proteins contained in ${totalSpecies} species reviewed by UniProt`,
      `The count of unique sequences of food-derived bioactive peptides encoded in ${totalSpecies} species`,
      `The average count of food-derived bioactive peptides accumulated in a single protein of each species`,
    ],
  }
  //------------------------------------------------------------------------------
  // const [tableData, setTableData] = useState({})
  const [sourceLabels, setSourceLabels] = useState([]);
  const [sourceValue, setSourceValue] = useState([]);
  const sourceOptions = chartProp.options.axisTitleXY(words.source.x, words.source.y)
  const sourceData = chartProp.data.carate(
    '',
    words.source.text,
    [''],
    sourceLabels,
    sourceValue,
    charts.colors,
  )
  
  const [classificationValue, setClassificationValue] = useState([]);
  const classificationOptions = chartProp.options.axisTitleXY(words.classification.x, words.classification.y)
  const classificationData = chartProp.data.carate(
    '',
    words.classification.text,
    [''],
    sourceLabels,
    classificationValue,
    charts.colors,
  )
  
  const [fragmentValue, setFragmentValue] = useState([]);
  const fragmentOptions = chartProp.options.axisTitleXY(words.fragment.x, words.fragment.y)
  const fragmentData = chartProp.data.carate(
    '',
    words.fragment.text,
    [''],
    sourceLabels,
    fragmentValue,
    charts.colors,
  )
  
  const [averageValue, setAverageValue] = useState([]);
  const averageOptions = chartProp.options.axisTitleXY(words.average.x, words.average.y)
  const averageData = chartProp.data.carate(
    '',
    words.average.text,
    [''],
    sourceLabels,
    averageValue,
    charts.colors,
  )
  
  //------------------------------------------------------------------------------
  const navigate = useNavigate()
  let location = useLocation()
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const apiTable = () => {
    const input = {} //jsonProtein.input('')
    const config = apiConfig.statistic.portein(input)
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let items = data[spp.common.output.items]
        let items_color = items.map((item, index) => {return {color: charts.colors[index], ...item}})
        setTableItems(items_color)
        // console.log('items', items);
        // console.log('items_color', items_color);
        
        let totalProteins = data['total_proteins']
        setTotalProteins(totalProteins)
        let totalPeptides = data['total_peptides']
        setTotalPeptides(totalPeptides)
        let totalSpecies = data['total_species']
        setTotalSpecies(totalSpecies)
        
        let arr = items.map(item => String(item["name"]))
        setSourceLabels(arr)
        arr = items.map(item => (item["source_total"]))
        setSourceValue(arr)
        arr = items.map(item => (item["peptide_total"]))
        setClassificationValue(arr)
        arr = items.map(item => (item["frag_total"]))
        setFragmentValue(arr)
        arr = items.map(item => (item["avg_count"]))
        setAverageValue(arr)
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const [selectChartIndex, setSelectChartIndex] = useState()
  const [selectBlockTitle, setSelectBlockTitle] = useState(charts.title[0])
    
  function handleClickMethod(index) {
    // let method = index+1
    setSelectChartIndex(index)
    // console.log('index', index);
    // console.log('method', method);
    
    let datasets = arrDatasets[index] ?? []
    // console.log(arrDatasets, datasets);
    setDatasets(datasets)
    setSelectBlockTitle(charts.title[index] ?? '')
    setTextChart(index)
  }
  
  const [chartTextResult, setChartTextResult] = useState(textChartResult(0, 0))
  function textChartResult(index, count) {
    switch(index) {
      case 1:
        return `Total peptides: ${count} results`;
      case 2:
        return `Total average peptides: ${count} results`;
      case 0:
      default:
        return `Total proteins: ${count} results`;
    }
  }
  function setTextChart(index) {
    let count = 0
    switch(index) {
      case 1:
        // count = chartOutput["total_peptides"]
        count = chartOutput[jsonChart.output.total_peptides]
        break
      case 2:
        // count = chartOutput["total_avg_peptides"]
        count = chartOutput[jsonChart.output.total_avg_peptides]
        break
      case 0:
      default:
        // count = chartOutput["total_proteins"]
        count = chartOutput[jsonChart.output.total_proteins]
    }
    // console.log('count', count);
    setChartTextResult(textChartResult(index, count))
  }
  
  
  //---------------------------------------------------------------------------
  // chart
  const [arrDatasets, setArrDatasets] = useState([])
  const [chartLabels, setLabels] = useState([])
  const [chartDatasets, setDatasets] = useState([])
  const [chartColors, setChartColors] = useState([v2.greenNormal])
  const [chartSources, setSources] = useState([])
  // chart setting
  const chartOptions = chartProp.options.statisticProtein(
    charts.chartLabelX, charts.chartLabelY, chartSources, chartLabels, selectChartIndex, navigate)
  const chartData = chartProp.data.carate(
    "",
    "",
    [''],
    chartLabels, //"x_axis"
    chartDatasets,
    chartColors,
  )
  
  //------------------------------------------------------------------------------
  // chart api
  const [chartOutput, setChartOutput] = useState({})
  // const [chartItems, setChartItems] = useState([])
  const [stateChart, setStateChart] = useState(axiosState.init())
  const apiChartResult = () => {
    const config = apiConfig.statistic.portein_chart()
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateChart(axiosState.error(false, stateChart.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateChart(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        setChartOutput(data)
        // console.log(data)
        let items = data[spp.common.output.items]
        // setChartItems(items)
        // console.log('items', items);
        
        //---------------------------------------------------------------------------
        let arrDatasets = charts.getArrDatasets(items)
        setArrDatasets(arrDatasets)
        // console.log('arrDatasets', arrDatasets);
        
        // let labels = items.map(item => String(item["name"]).removeAll('\n'))
        let labels = items.map(item => String(item["name"]))
        setLabels(labels)
        // console.log('labels', labels);
        let sources = items.map(item => String(item["source_code"]))
        setSources(sources)
        // console.log('sources', sources);
        
        let colors = items.map(item => {
          // console.log(item);
          let code = item['class_code']
          let type = code.charAt(1)
          let index = type > 0 ? Number(type) -1 : 0
          return (charts.colors[index])
        })
        setChartColors(colors)
        // console.log('colors', colors);
      }
    }).catch(err => {
      setStateChart(axiosState.error(axiosCatch.isTimeout(err), stateChart.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useEffect(() => {
    apiTable()
    apiChartResult()
  }, []);
  
  useEffect(() => {
    //setup init chart data with arrDatasets[0]
    handleClickMethod(0)
  }, [chartLabels])

  // -----render-------------------------------------------------------
  
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scss.layout_card}>
          {stateTable.isLoading ? <LoadingAnime />
          : tableItems.map((item, index) => (
            <div className={scss.card}>
              <StatCard item={item}></StatCard>
            </div>
            ))}
      </div>
      
      <Space />
      
        <div className={scss.chart_type_btns}>
          {charts.methodBtnList.map((name, index) => {
            return (
              <GreenButton
                key={index}
                // variant="outlined"
                index={index}
                className={selectChartIndex === index ? scss.selected_chart_type : ""}
                onClick={() => {handleClickMethod(index)}}
              >{name}</GreenButton>)
            })
          }
        </div>
        
        {stateChart.isLoading ? <LoadingAnime />
        : <ChartSection
            sectionTitle={charts.chartTitle}
            chartBlockTitle={selectBlockTitle}
            chartBlockSubTitle={chartTextResult}
            mainChart={
              <BarChart
                data={chartData}
                options={chartOptions}
                showTableLabel={false}
                yAxisLabel={charts.chartLabelY}
                showAvgLine={false}
                needHoverStyle={true}
              />
            } />
        }
          
        <Space />
        
        <div className={scss.layout_seq}>
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.source.title}
              // chartBlockSubTitle={words.source.text}
              mainChart={
                <BarChart
                  data={sourceData}
                  options={sourceOptions}
                  yAxisLabel={words.source.y} />
              } />
          </div>
          
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.classification.title}
              // chartBlockSubTitle={words.classification.text}
              mainChart={
                <BarChart
                  data={classificationData}
                  options={classificationOptions}
                  yAxisLabel={words.classification.y} />
              } />
          </div>
            
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.fragment.title}
              // chartBlockSubTitle={words.fragment.text}
              mainChart={
                <BarChart
                  data={fragmentData}
                  options={fragmentOptions}
                  yAxisLabel={words.fragment.y} />
              } />
          </div>
          
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.average.title}
              // chartBlockSubTitle={words.average.text}
              mainChart={
                <BarChart
                  data={averageData}
                  options={averageOptions}
                  yAxisLabel={words.average.y} />
              } />
          </div>
        </div>
    </LayoutPageV2>)
};