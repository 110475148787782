import { useEffect, useState } from 'react';
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { breadData } from 'components/bread/BreadBarV2';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import { apiConfig } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import paths from 'utils/network/apiPath';
import { linkData } from 'utils/links';
import { getFullPath } from 'utils/general';

//---------------------------------------------------------------------------
// http://localhost:3000/peptide_quick_cls?cls=antioxidative_peptides
//Go in: homepage -> 'Peptide' tab -> any text -> 'Search' button -> result page -> 'Results' number(>0) -> this page
// path: paths.spp.peptide.quick_classify_search,
const PeptideClassifyQuickSearch = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const queryClass = searchParams.get(paths.params.class)
  // console.log('queryClass', queryClass)
  const [breadTitle, setTableTitle] = useState("")

  let location = useLocation()
  let breadText = breadTitle.trimClassifyName()
  let bread = linkData(breadText, getFullPath(location))
  let json4st = JSON.stringify(bread)
  // console.log(bread, json4st);
  cookie.setCookie(cookie.keys.protein.bread4th, json4st)
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let texts = {
    bread: breadData.create('Peptide', breadTitle, bread3rd, breadText),

    searchHint: 'Search by SPP ID, AA sequence, AA length, Function counts, Multifunctional activities',
    tableHeadCell: spp.peptide.search.quick_classify.output_text,
    tableBodyCell: spp.peptide.search.quick_classify.output,
  }

  let filterName = cookie.getCookie(cookie.keys.quickSearch.filterSelection)
  let filterValue = cookie.getCookie(cookie.keys.quickSearch.filterValue)
  // console.log('filter:', filterName, ' value:', filterValue);

  useEffect(() => {

    const peptideName = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName)

    if (peptideName) { //NOT undefined, null, ""
      setTableTitle(`${peptideName} peptides`)
    }

  }, [])

  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={apiConfig.peptide.quick_class_search} //12
      jsonInput={spp.peptide.search.quick_classify.input}
      inputProp={[queryClass, filterName, filterValue]}
      jsonInputKeyword={spp.peptide.search.quick_classify.input_keyword}
    />
  )
}
export default PeptideClassifyQuickSearch