/*
react-git-info - npm
https://www.npmjs.com/package/react-git-info
 */
import GitInfo from "react-git-info/macro";

/*
Javascript Date 的健忘筆記 - ScottChayaa
https://blog.scottchayaa.com/post/2019/05/27/javascript_date_memo/
 */
/* eslint-disable-next-line */
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小時
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };

  const match = fmt.match(/(y+)/);
  if (match) {
    const str = match[1];
    fmt = fmt.replace(str, (this.getFullYear() + "").substring(4 - str.length));
  }

  for (var k in o) {
    const matchPattern = new RegExp(`(${k})`).exec(fmt);
    if (matchPattern) {
      const str = matchPattern[1];
      fmt = fmt.replace(
        str,
        str.length === 1 ? o[k] : ("00" + o[k]).slice(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

export const gitInfo = GitInfo();
export default function showVersion() {
  const appVer = process.env.REACT_APP_VERSION;
  const gitInfo = GitInfo();
  // console.log(gitInfo.branch);
  // console.log(gitInfo.tags);
  // console.log(gitInfo.commit.date);
  // console.log(gitInfo.commit.hash);
  // console.log(gitInfo.commit.message);
  // console.log(gitInfo.commit.shortHash);

  const gitVer = new Date(gitInfo.commit.date).format("yyyyMMdd.hhmmss");
  // console.log(appVer);

  // version branch hash
  // e.g. 20230526.134453 dev 1ad880e
  // const txtVer = gitVer +' '+ gitInfo.branch +' '+ gitInfo.commit.shortHash;
  const txtVer =
    "version " +
    appVer +
    "." +
    gitVer +
    " " +
    gitInfo.branch +
    " " +
    gitInfo.commit.shortHash;
  // const txtVer = 'version ' + appVer;
  console.log(txtVer);
  return txtVer;
}
