import { useEffect, useState } from 'react';
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";

import 'assets/scss/temp.scss';
import { spp } from 'utils/network/jsons';
import { apiConfig } from 'utils/network/axios';
import { breadData } from 'components/bread/BreadBarV2';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import { linkData } from 'utils/links';
import { getFullPath } from 'utils/general';

//---------------------------------------------------------------------------
// http://localhost:3000/protein_cls
// Go in: homepage -> 'Protein' tab -> 'Milk' 'Plant' 'Animal' 'Marine' 'Microorganism' -> this page
// path: paths.spp.protein.classify_search,
const ProteinClassificationSearch = ({ setInfo }) => {
  const [searchParams] = useSearchParams()

  const clsName =  cookie.getCookie(cookie.keys.protein.classGraphSelectedClsName)
  const clsID = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsID)
  
  const [breadTitle, setTableTitle] = useState(clsName !== undefined ? `${clsName} proteins` : "")
  
  let location = useLocation()
  let breadText = breadTitle.trimClassifyName()
  let bread = linkData(breadText, getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log(bread, json3rd);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  let texts = {
    bread: breadData.create('Protein', breadTitle, breadText),

    searchHint: "Search by SPP ID, Organism, Protein name, Protein length, UniProtKB",
    tableHeadCell: spp.protein.search.classify.output_text,
    tableBodyCell: spp.protein.search.classify.output,
  }

  useEffect(() => {
    if (clsName === undefined) {
      setTableTitle("")
    }
  
  }, [clsName])

  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={apiConfig.protein.classify_search} //11
      jsonInput={spp.protein.search.classify.input}
      inputProp={[clsID]}
      jsonInputKeyword={spp.protein.search.classify.input_keyword}
    />
  )
}
export default ProteinClassificationSearch