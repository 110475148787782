import { useEffect } from "react";
import { useNavigate } from "react-router";

import scss from 'components/chart/VennDiagram.module.scss'
import cookie from "utils/cookie";
import paths from "utils/network/apiPath";
import { images } from "utils/imgs";
import Text from "components/tag/Text";
import { spp } from "utils/network/jsons";

//---------------------------------------------------------------------------
const jsonsVenn = spp.tool.css_tool_result_lv1_venn_diagram_all
const dataFake2 = {
  "labels": [
    "Yeast",
    "Agaricus bisporus",
    "Yeast ∩ Agaricus bisporus"
  ],
  "datasets": [
    {
      "data": [
        {
          "sets": [
            "Yeast"
          ],
          "label": "Yeast",
          "value": 11,
        },
        {
          "sets": [
            "Agaricus bisporus"
          ],
          "label": "Agaricus bisporus",
          "value": 0,
        },
        {
          "sets": [
            "Yeast",
            "Agaricus bisporus"
          ],
          "label": "Yeast ∩ Agaricus bisporus",
          "value": 3,
        }
      ]
    }
  ]
}
const dataFake3 = {
  "labels": [
    "Yeast",
    "Lactobacillus",
    "Agaricus bisporus",
    "Yeast ∩ Lactobacillus",
    "Yeast ∩ Agaricus bisporus",
    "Lactobacillus ∩ Agaricus bisporus",
    "Yeast ∩ Lactobacillus ∩ Agaricus bisporus"
  ],
  "datasets": [
    {
      "label": "Venn Diagram",
      "data": [
        {
          "sets": [
            "Yeast"
          ],
          "label": "Yeast",
          "value": 226,
        },
        {
          "sets": [
            "Lactobacillus"
          ],
          "label": "Lactobacillus",
          "value": 4,
        },
        {
          "sets": [
            "Agaricus bisporus"
          ],
          "label": "Agaricus bisporus",
          "value": 1,
        },
        {
          "sets": [
            "Yeast",
            "Lactobacillus"
          ],
          "label": "Yeast ∩ Lactobacillus",
          "value": 45,
        },
        {
          "sets": [
            "Yeast",
            "Agaricus bisporus"
          ],
          "label": "Yeast ∩ Agaricus bisporus",
          "value": 89,
        },
        {
          "sets": [
            "Lactobacillus",
            "Agaricus bisporus"
          ],
          "label": "Lactobacillus ∩ Agaricus bisporus",
          "value": 0,
        },
        {
          "sets": [
            "Yeast",
            "Lactobacillus",
            "Agaricus bisporus"
          ],
          "label": "Yeast ∩ Lactobacillus ∩ Agaricus bisporus",
          "value": 367,
        }
      ]
    }
  ]
}
//---------------------------------------------------------------------------
const VennDiagramImage = ({ data, items, source, cls, keyword, isSearchFragment, ...props }) => {
  const navigate = useNavigate()
  //demo data
  // data = dataFake2
  // data = dataFake3
  // console.log('data', data);
  // console.log(isSearchFragment);
  const isVeen2 = ( data !== undefined && data.labels !== undefined && data.labels.length === 3 ) //a, b, ab
  
  function handleClick(event, array) {
    // console.log('event', event);
    if (array.length > 0) {
      const firstPoint = array[0];
      const area = data.data[firstPoint.index];
      // console.log("click area:", area);
      const label = area[jsonsVenn.items[1]] //"label(s)",
      const ids = area[jsonsVenn.items[2]] //"code(s)"
      // console.log("label:", label, "ids:", ids);
      cookie.setCookie(cookie.keys.tool.crsSelectVennLv3, label)
      if( isSearchFragment )
        navigate(paths.spp.tool.css_tool_result_lv3(source, {keyword: keyword, venn: ids}));
      else
        navigate(paths.spp.tool.css_tool_result_lv3(source, {cls: cls, venn: ids}));
    }
  }
  function handleClickText(index) {
    let array = []
    array.push({index: index})
    handleClick(this, array)
  }  
  
  const scssVenn2Number = [
    scss.venn2_number_left,
    scss.venn2_number_right,
    scss.venn2_number_center,
  ]
  const scssVenn2Label = [
    scss.venn2_label_left,
    scss.venn2_label_right,
    scss.venn2_label_center,
  ]
  const scssVenn3Number = [
    scss.venn3_number_a,
    scss.venn3_number_b,
    scss.venn3_number_c,
    scss.venn3_number_ab,
    scss.venn3_number_ac,
    scss.venn3_number_bc,
    scss.venn3_number_abc,
  ]
  const scssVenn3Label = [
    scss.venn3_label_a,
    scss.venn3_label_b,
    scss.venn3_label_c,
    scss.venn3_label_ab,
    scss.venn3_label_ac,
    scss.venn3_label_bc,
    scss.venn3_label_abc,
  ]
  const NumberText = (item, index) => (
    <Text key={index} center className={`h4-darkGreen ${item}`} onClick={() => handleClickText(index)}>
      {data.data[index].count}
    </Text>)
  const LabelText = (item, index) => {
    if(index === 5) {
      return (
        <Text key={index} right className={`Contents-QuanticoBody20px-Bold-White_50 ${item}`} onClick={() => handleClickText(index)}>
          {data.labels[index]}</Text>
      )
    } else {
      return (
        <Text key={index} className={`Contents-QuanticoBody20px-Bold-White_50 ${item}`} onClick={() => handleClickText(index)}>
          {data.labels[index]}</Text>
      )}
  }
  
  return (
    <>
      {
      isVeen2
      ? <div className={`${scss.venn2_img}`}
          style={{ backgroundImage:`url(${images.ToolCRS['venn2.png']})` }}>
          {data !== undefined && data.data !== undefined
              ? <div className={'frame_row'}>
              {NumberText(scssVenn2Number[0], 0)}
              {NumberText(scssVenn2Number[2], 2)}
              {NumberText(scssVenn2Number[1], 1)}
            </div>
          : null }
          {data !== undefined && data.labels !== undefined
          ? <div className={`frame_col`}>
              {LabelText(scssVenn2Label[0], 0)}
              {LabelText(scssVenn2Label[2], 2)}
              {LabelText(scssVenn2Label[1], 1)}
            </div>
          : null }
        </div>
        
      : <div className={`${scss.venn3_img}`}
          style={{ backgroundImage:`url(${images.ToolCRS['venn3.png']})` }}>
          {data !== undefined && data.data !== undefined
              ? <div className={'frame_row'}>
              {scssVenn3Number.map((item, index) => (NumberText(item, index)))}
            </div>
          : null }
          {data !== undefined && data.labels !== undefined
              ? <div className={'frame_col'}>
              {scssVenn3Label.map((item, index) => (LabelText(item, index)))}
            </div>
          : null }
        </div>
      }
    </>
  );
};
export default VennDiagramImage;
