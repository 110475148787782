import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  registerData: {
    // user_id:"",
    // username:"",
    // phone:"",
    email: '',
    password: '',
    confirm_password: '',
  }, // TODO: Will need cookie
}

export const registerSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setRegisterData: (state, action) => {
      state.registerData[action.payload.index] = action.payload.value
    },
  },
})

// Action creators are generated for each case reducer function
export const { setRegisterData } = registerSlice.actions

export default registerSlice.reducer
