import Cookies from 'universal-cookie'

import paths from './network/apiPath'
import { getFullPath } from './general';

//---------------------------------------------------------------------------
const keys = {
  auth: { //user
    token: "user_token",
    id: "user_id",
    nickname: "user_nickname",
    email: "user_email",
    isChecked: "user_isChecked",
    password: "user_pw",
    confirmPassword: "user_confirmPw",
  },

  quickSearch: {
    //for search page
    tabName: "quickSearch_tabName",
    typeIndex: "quickSearch_typeIndex",
    //for result page
    typeName: "quickSearch_typeName",
    //for api
    filterSelection: "quickSearch_filterSelection",
    filterValue: "quickSearch_filterValue",
  },

  peptide:{
    bitterPeptideSearchSeq: "bitterPeptideSearchSeq",
    calPeptideSearchSeq: "calPeptideSearchSeq",
    selectedPeptideClsId: "selectedPeptideClsId",
    selectedPeptideClsName: "selectedPeptideClsName",
    selectedPeptideClsTableName: "selectedPeptideClsTableName",
    selectedMultiPeptideList: "selectedMultiPeptideList",
    
    // Advanced Search
    peptideAdvUserCls: 'peptideAdvUserCls',
    peptideAdvUserFilter: 'peptideAdvUserFilter',
    peptideAdvUserFilterLabel: 'peptideAdvUserFilterLabel',
    peptideAdvUserKeyword: 'peptideAdvUserKeyword',
  },

  protein:{
    classGraphSelectedSourceID: "classGraphSelectedSourceID",
    classGraphSelectedSourceName: "classGraphSelectedSourceName",
    classGraphSelectedClsID: "classGraphSelectedClsID",
    classGraphSelectedClsName: "classGraphSelectedClsName",
    
    bread3rd: 'proteinBread3rd',
    bread4th: 'proteinBread4th',
    bread5th: 'proteinBread5th',
    
    listSelectClsName: 'listSelectClsName',
  },
  
  tool:{
    // 4.1
    hotspotUserProtein: 'hotspotUserProtein',
    hotspotUserProteinClass: 'hotspotUserProteinClass',
    hotspotUserClass: 'hotspotUserClass',
    hotspotUserSource: 'hotspotUserSource',
    hotspotUserName: 'hotspotUserName',
    hotspotUserKeyword: 'hotspotUserKeyword',
    // 4.2
    ehpUserClassID: "ehpClassID",
    ehpUserSourceID: "ehpSourceID",
    ehpUserProteinID:  "ehpProteinID",
    ehpUserKeyword: "ehpKeyword",
    ehpUserEnzymeID: 'ehpEnzymeID',
    // 4.4
    aasdUserDescriptor: 'aasdUserDescriptor',
    // 4.7
    pdcaasSelectOrganism: 'pdcaasSelectOrganism',
    pdcaasSelectWeight: 'pdcaasSelectWeight',
    pdcaasSelectMethod: 'pdcaasSelectMethod',
    // 4.8
    ppspManualInput: 'ppspManualInput',
    ppspJobSeq: 'ppspJobSeq',
    ppspJobName: 'ppspJobName',
    ppspJobFile: 'ppspJobFile',
    ppspJobDisplayName: 'ppspJobDisplayName',
    ppspJobID: 'ppspJobID',
    ppspJobType: 'ppspJobType',
    // 4.8.2
    ppdManualInput: 'ppdManualInput',
    ppdDockingSeq: 'ppdDockingSeq',
    // result ui use ppsp ui & data
    // 4.9
    crsSelectSource: 'crsSelectSource',
    crsSelectCls: 'crsSelectCls',
    crsSelectSourceLv2: 'crsSelectSourceLv2',
    crsSelectSourceLv3: 'crsSelectSourceLv3',
    crsSelectVennLv3: 'crsSelectVennLv3',
    // crsSelectClsLv3: 'crsSelectClsLv3',
    //for save state
    crsSelectSourceId: 'crsSelectSourceId',
    crsSelectClsId: 'crsSelectClsId',
    crsSelectClsTable: 'crsSelectClsTable',
    // 4.11
    aceippManualInput: 'aceippManualInput',
    aceippUploadResult: 'aceippUploadResult',
    // 4.12
    anoxppManualInput: 'anoxppManualInput',
    anoxppUploadResult: 'anoxppUploadResult',
  },
};

//---------------------------------------------------------------------------
const cookies = new Cookies();

/**
 * set cookie's key and value
 * @param {string} key
 * @param {*} value
 */
const setCookie = (key, value) => {
  cookies.set(key, value, {
    path: "/",
    secure: true,
    sameSite: true,
    // sameSite: "strict",
    expires: 0,
  })
  // console.log(cookies.get(key));
};

const setCookieOneYearExpired = (key, value) => {
  // Make cookie expired after 1 year
  let today = new Date();
  today.setFullYear(today.getFullYear() + 1);
  let oneYear = today;

  cookies.set(key, value, {
    path: "/",
    secure: true,
    sameSite: true,
    // sameSite: "strict",
    expires: oneYear,
  });
  // console.log(cookies.get(key));
};

/**
 * get cookie's value
 * @param {string} key
 */
const getCookie = (key) => {
  if (cookies.get(key) === undefined) {
    return "";
  }
  return cookies.get(key);
};

/**
 * remove cookie
 * @param {string} key
 */
const removeCookie = (key) => {
  cookies.remove(key);
};

//---------------------------------------------------------------------------
const removeCookieUser = () => {
  cookies.remove(keys.auth.token);
  cookies.remove(keys.auth.email);
  cookies.remove(keys.auth.id);
  cookies.remove(keys.auth.nickname);
  cookies.remove(keys.auth.password);
  cookies.remove(keys.auth.confirmPassword);
};

const removeCookieAndJump = (navigate, location) => {
  removeCookieUser()
  // console.log('location', location)
  if( location === undefined )
    setTimeout( () => {navigate(paths.auth.login)}, 1500 );
  else {
    let fullPath = getFullPath(location)
    setTimeout( () => {navigate(`${paths.auth.login}?redirect=${encodeURIComponent(fullPath)}`)}, 1500 );
  }
}

//---------------------------------------------------------------------------
const cookie = {
  setCookie,
  setCookieOneYearExpired,
  getCookie,
  removeCookie,
  removeCookieUser,
  removeCookieAndJump,
  
  keys,
};
export default cookie;
