import { useSearchParams } from "react-router-dom";
import { TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';

import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import paths from "utils/network/apiPath";
import { breadData } from 'components/bread/BreadBarV2';
import LayoutPageV2 from "layouts/LayoutPageV2";
import cookie from "utils/cookie";
import { Code } from "components/tag/Text";
import { getRow } from 'components/table/table';
import { useEffect, useState } from "react";
import StyledTableCell from 'components/table/StyledTableCell';
import { MuiTableContainer as TableContainer } from "components/table/MuiTableV2";
import { color } from "echarts";

//------------------------------------------------------------------------------

let texts = {
  bread_sp:  breadData.create('Tools', 'Protein & Peptide Structure Prediction Results (PPSPred-V1.0)', 'Structure Prediction', 'Prediction Result'),
  bread_docking:  breadData.create('Tools', 'Protein-Peptide Docking Results (PPDock-V1.0)', 'Docking', 'Docking Result'),
  
  table: {
    head: [
      'ID',
      'Job Name',
      "Sequence length",
      "Sequence",
    ],
    json: [ //for style
      "job_id",
      "job_name",
      "sequence_length",
      "sequence",
    ],
  },
}
texts.table_docking = {
  head: [
    ...texts.table.head.slice(0, 1+1),
    "Sequence A length",
    "Sequence A",
    "Sequence B length",
    "Sequence B",
  ],
  json: [ //for style
    ...texts.table.json,
    "sequence_length_b",
    "sequence_b",
  ],
}

const MuiTableContainer = (props) => (
  <TableContainer size='small' isPurple={props.isPurple}>
    {props.children}
  </TableContainer>
)

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableHeadCell1st = (props) => {
  const isDocking = props.isDocking ? props.isDocking : false
  const backgroundColor = isDocking ? 'var(--logo-colors-logo-purple-16)' : 'var(--green-alpha-alpha-16)'
  return (
  <StyledTableCell style={{ width: 190, backgroundColor: backgroundColor}} align='right'
    component="th" scope="row" {...props}>
    {props.children}
  </StyledTableCell>
)}

//---------------------------------------------------------------------------
export default function ToolPPStructurePredictionResult({ setInfo }) {
  const [searchParams] = useSearchParams()
  const type = searchParams.get(paths.params.type)
  const isDocking = (type === 'd')
  const dockingSequence = cookie.getCookie(cookie.keys.tool.ppdDockingSeq)
  const jobSequence = cookie.getCookie(cookie.keys.tool.ppspJobSeq)
  //move to ${paths.af3}/index_af3.html
  const jobID = cookie.getCookie(cookie.keys.tool.ppspJobID)
  const displayName = cookie.getCookie(cookie.keys.tool.ppspJobDisplayName)
  // const jobName = cookie.getCookie(cookie.keys.tool.ppspJobName)
  // const jobFile = cookie.getCookie(cookie.keys.tool.ppspJobFile) //"fold_eyklvvvgac",
  // const cifFile = `${jobName}/${jobFile}_model_0.cif` // fold_eyklvvvgac_model_0.cif
  // console.log(jobSequence);
  // console.log(jobName);
  // console.log(jobFile);
  
  texts.bread = isDocking ? texts.bread_docking : texts.bread_sp
  
  //只需要把這個變量對應起來即可，就是肽的序列
  // const peptideSequence = "AA";
  const peptideSequence = "";
  // console.log('peptideSequence', peptideSequence);
  
  const [output, setOutput] = useState({})
  const [rows, setRows] = useState(getRows(texts, ''))
  function getRows(texts, output) {
    if(isDocking) {
      return ([
        getRow(texts.table_docking, output),
      ])
    } else {
      return ([
        getRow(texts.table, output),
      ])
    }
  }
  
  const rowChildren = (row, index) => {
    // console.log(row);
    let name = row.name
    let value = row.value
    // console.log(name, value);
    switch(name) {
      case 'ID':
        value = jobID;
        break
      case 'Job Name':
        value = displayName;
        break
      case "Sequence length":
      case "Sequence A length":
        value = jobSequence.length
        break
      case "Sequence":
      case "Sequence A":
        value = (
          <div className={scssPeptide[getScssNameCell(name)]}>
            <Code>{jobSequence.toCode()}</Code>
          </div>)
        break
      case "Sequence B length":
        value = dockingSequence.length
        break
      case "Sequence B":
        value = (
          <div className={scssPeptide[getScssNameCell(name)]}>
            <Code>{dockingSequence.toCode()}</Code>
          </div>)
        break
    }
    
    return (
      <TableRow key={name}>
        <MuiTableHeadCell1st isDocking={isDocking}>{name}</MuiTableHeadCell1st>
        <MuiTableCell>{value}</MuiTableCell>
      </TableRow>)
  }
  
  function getScssNameCell(name = '', json = '') {
    // console.log(name, json);
    if (name === "Sequence" || json === "sequence" ||
      name === "Sequence A" || 
      name === "Sequence B" || json === "sequence_b" )
    {
      if(isDocking) {
        return 'right_cell_code_purple'
      }
      else
        return 'right_cell_code'
    }
    return ''
  }
  
  useEffect(() => {
    setRows(getRows(texts, output))
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread}>
        <MuiTableContainer isPurple={true}>
          <TableBody style={{border:'none'}}>
            {rows[0].map((row, index) => (
              rowChildren(row, index)
            ))}
          </TableBody>
        </MuiTableContainer>
        <div style={{height:'10px'}}></div>
        
        <div style={{
          position: 'relative',
          // width: '95vw', 
          height: '95vh',
           }}>
        <iframe
          src={`${process.env.PUBLIC_URL}${paths.af3}/index_af3.html`}
          width="100%"
          height="540px" //40px + height(molstart index.html #app)
          title="JS MolstarViewer"
          style={{ border: 'none' }}
        />
      </div>
    </LayoutPageV2>);
};
