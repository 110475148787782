

export function getRow(table, output) {
  let rows = []
  table.head.map(function (item, index) {
    let name = table.json[index]
    return (rows.push(rowData(item, output[name])))
  })
  return (rows)
}
export function rowData(name, value) {
  return { name, value };
}
