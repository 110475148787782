import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';

import 'assets/scss/common.scss';
import v from 'assets/scss/_variables.scss'

//---------------------------------------------------------------------------
export const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table_container"}>
    <Table aria-label="table" {...props}>
      {props.children} 
    </Table>
  </TableContainer>
)

export const TableHeadRow = (props) => (
  <TableHead {...props}>
    <TableRow>
      {props.children}
    </TableRow>
  </TableHead>
)

export const MuiTableBody = (props) => (
  <TableBody sx={{ border: 0, }}>
    {props.children}
  </TableBody>
)

export const TableRow2Body = (props) => {
  let _sx = { '&:last-child td, &:last-child th': { border: 0 }, }
  if (props.sx !== undefined)
    Object.assign(_sx, props.sx)
  // console.log(_sx);
  return (
  <TableRow sx={_sx} {...props}>
    {props.children}
  </TableRow>)
}

export const MuiTableCell = ({ ...props }) => {
  let sxs = {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 'bold',
      whiteSpace: 'pre-line',

      height: '64px',
      boxSizing: 'border-box',
      maxWidth: '180px',

      color: v.white,
    },
    [`&.${tableCellClasses.body}`]: {
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',

      height: '60px',
      boxSizing: 'border-box',
      maxWidth: '180px',

      color: v.$darkGreen,
    },
  }
  if (props.sx !== undefined)
    Object.assign(sxs, props.sx)
  return (
    <TableCell sx={sxs} {...props}>
      {props.children}
    </TableCell>
  )
}
