import { useEffect, useState } from 'react';
import {
  useSearchParams,
} from "react-router-dom";

import 'assets/scss/temp.scss';
import { spp } from 'utils/network/jsons';
import { apiConfig } from 'utils/network/axios';
import { breadData } from 'components/bread/BreadBarV2';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_pdcaas_t
// Go in: homepage -> 'Tools' tab -> 'PDCAAS' button -> click 'Organism List' button -> this page
// path: paths.spp.tool.pdcaas_table
const ToolPDCAASTable = ({ setInfo }) => {
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let texts = {
    bread: breadData.create('Tools', 'DPQEHN', bread3rd, 'DPQEHN'),

    searchHint: "Search by SPP ID, Sequence, Term, Classification, Source, Precursor protein, PubDate",
    tableHeadCell: spp.tool.pdcaas_table.items_text,
    tableBodyCell: spp.tool.pdcaas_table.items,
  }

  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={apiConfig.tool.pdcaas_table} //97
      jsonInput={spp.tool.pdcaas_table.input}
      inputProp={[]}
      jsonInputKeyword={spp.tool.pdcaas_table.input_keyword}
    />
  )
}
export default ToolPDCAASTable