import TableBody from '@mui/material/TableBody';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

// -----custom tools-------------------------------------------------------
import 'assets/scss/commonV2.scss';
import scss from './ToolACEiPPPreLibraries.module.scss';
import scssACEiPP from './ToolACEiPP.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import { TagText } from 'components/tag/Text';
import { snackInfo, snackType } from 'components/SnackBar';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import fs from 'utils/files';
import { spp } from 'utils/network/jsons';
import chartProp from 'components/chart/chartProp';
import LoadingAnime from 'components/LoadingAnime';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import { getKeyByValue } from 'utils/object';
import { Space } from 'utils/general';
import { MuiTableCell4Head, chartDataBtnList, chartTextData } from './ToolACEiPPSeqFeatures';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GreenButton from 'components/button/GreenButton';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_aceipp_anoxpp_pp?&dataset=1&i=1
// http://localhost:3000/tool_aceipp_anoxpp_pp?&dataset=2&i=7
// path: paths.spp.tool.aceipp_anoxpp_pp
const jsonsTable = spp.tool.aceipp_anoxpp_pre_pool_table
const texts = {
  tableHeadCell: jsonsTable.items_text,
  tableBodyCell: jsonsTable.items,
  
  terminal_labels: [
    'N-terminal',
    'C-terminal',
  ],
  
  tool_names: [
    'ACEiPP',
    'AnOxPP',
  ]
}

//---------------------------------------------------------------------------
const ToolACEiPPAnOxPPPrePool = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const datasetStr = searchParams.get(paths.params.dataset)
  const dataset = Number(datasetStr)
  const ind = searchParams.get(paths.params.ind)
  // console.log('dataset', dataset);
  // console.log('ind', ind);
  
  const datasetName = getKeyByValue(jsonsTable.dataset, dataset)
  // console.log(datasetName);
  const toolIndex = dataset - 1
  const toolName = (toolIndex >= 0 && toolIndex < texts.tool_names.length) ? texts.tool_names[toolIndex] : ''
  
  const chartLabelY = 'Percentage (%)'
  const [fileName, setFileName] = useState('')
  const words = {
    bread: breadData.create('Tools', 'Sequence Feature Analysis', `${toolName} Pre-Libraries`, `${toolName} Pre-Pool` ),
    
    intro: `Perform sequence feature analysis on all samples in the file "${fileName}" including: length, mass distribution, N- and C- terminals, and amino acid composition.`,
    chartTitle: 'The sequence features of benchmark datasets',
    
    chartLength: chartTextData('Statistics of peptide length', 'Peptide length', chartLabelY),
    chartMass: chartTextData('Statistics of peptide mass', 'MW distribution', chartLabelY),
    chartTerminal: chartTextData('Statistics of peptide terminal', 'Amino acid', chartLabelY),
    chartComposition: chartTextData('Statistics of amino acid composition', 'Amino acid', chartLabelY),
  }
  
  //------------------------------------------------------------------------------
  // table
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiTableResult = () => {
    const input = jsonsTable.input(dataset, ind)
    const config = apiConfig.tool.aceipp_anoxpp_pre_pool_table(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        // console.log(data)
        let items = data[spp.common.output.items]
        setTableItems(items)
        // console.log('items', items);
        
        if( Array.isArray(items) && items.length >= 1 ) {
          let name = items[0].pool_name
          setFileName(name)
        }
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    let isDownload = false
    let isAliceLeft = false
    let value, text, align, rowSpan = 1
    return (
      tableBodyCell.map((cell, indexX) => {
        value = children[cell]
        text = value
        // console.log('cell', cell, 'value', value);
        
        align = "center"
        if(indexX <= 1 )
          align = "left"
        if(cell === 'download') { //Download
          isDownload = true
          value = children["pool_name"]
        }
        if(cell === "ind")
          return null
              
        return (
          <MuiTableCell key={index+''+indexX+''+cell} align={align} rowSpan={rowSpan}
            style = {{paddingTop: '2px', paddingBottom: '2px', height: '32px'}}>
            {isDownload
            ? cellDownload(value, dataset)
            : text}
          </MuiTableCell>)
      }))
  }
  
  const cellDownload = (name, dataset) => {
    // console.log(index, dataset);
    if(!name)
      return null
    
    let file = undefined, filePath
    if( dataset === jsonsTable.dataset.ACEiPs ) {
      // file = files.ToolACEiPP.PreLibraries[name]
      filePath = fs.filePaths.ToolACEiPP.PreLibraries
      file = `${process.env.PUBLIC_URL}${filePath}${name}`
    } else if( dataset === jsonsTable.dataset.AnOxPs ) {
      // file = files.ToolAnOxPP.PreLibraries[name]
      filePath = fs.filePaths.ToolAnOxPP.PreLibraries
      file = `${process.env.PUBLIC_URL}${filePath}${name}`
    }
    
    if( file !== undefined ) {
      return (
        <a href={file} download={name} >
          <CloudDownloadOutlinedIcon />
        </a>)
    } else
      return null
  }
  
  //---------------------------------------------------------------------------
  // chart
  const [chartTextResult, setChartTextResult] = useState(textChartResult(0))
  function textChartResult(numACEiPs) {
    return `Reported ${datasetName}: ${numACEiPs} results`;
  }
  
  // const [outputLength, setLength] = useState({})
  const [chartLengthLabels, setLengthLabels] = useState([])
  const [chartLength, setLengthPos] = useState([])
  
  // const [outputMass, setMass] = useState({})
  const [chartMassLabels, setMassLabels] = useState([])
  const [chartMass, setMassPos] = useState([])
  
  // const [outputTerminal, setTerminal] = useState({})
  const [chartTerminalLabels, setTerminalLabels] = useState([])
  const [chartTerminalN, setTerminalPosN] = useState([])
  const [chartTerminalC, setTerminalPosC] = useState([])
  
  // const [outputComposition, setComposition] = useState({})
  const [chartCompositionLabels, setCompositionLabels] = useState([])
  const [chartComposition, setCompositionPos] = useState([])
  
  const jsonsChart = spp.tool.aceipp_anoxpp_seq_chart
  const [state, setState] = useState(axiosState.init())
  const apiChartResult = (method) => {
    const input = jsonsChart.input(dataset, method, ind)
    const config = apiConfig.tool.aceipp_anoxpp_seq_chart(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        // console.log(data)
        let labels = data[jsonsChart.output[2]] //"x_axis",
        let pos = data[jsonsChart.output[3]] //"pos_value",
        // console.log('labels', labels);
        switch(method) {
          case jsonsChart.method.Length:
            // console.log('Length', data)
            // setLength(data)
            setLengthLabels(labels)
            setLengthPos(pos)
            break
          case jsonsChart.method.Mass:
            // console.log('Mass', data)
            // setMass(data)
            setMassLabels(labels)
            setMassPos(pos)
            break
          case jsonsChart.method.Terminal:
            // console.log('Terminal', data)
            // setTerminal(data)
            setTerminalLabels(labels)
            if( Array.isArray(pos) ) {
              setTerminalPosN(pos[0])
              setTerminalPosC(pos[1])
            }
            break
          case jsonsChart.method.Composition:
            // console.log('Composition', data)
            // setComposition(data)
            setCompositionLabels(labels)
            setCompositionPos(pos)
            break
          default:
            break
        }
        
        let results = data[jsonsChart.output[0]] //"pos_total"
        setChartTextResult(textChartResult(results))
        
        setSelectChartMethod(chartMethod.Length)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      
      setChartTextResult(textChartResult(0))
      setSelectChartOptions([])
      setSelectChartData([])
    })
  }
  
  //------------------------------------------------------------------------------
  // chart data
  const chartLengthOptions = chartProp.options.axisTitleXY(words.chartLength.x, words.chartLength.y)
  const chartLengthData = chartProp.data.carate(
    "",
    "",
    [''],
    chartLengthLabels, //"x_axis"
    chartLength,
  )
  
  const chartMassOptions = chartProp.options.axisTitleXY(words.chartMass.x, words.chartMass.y)
  const chartMassData = chartProp.data.carate(
    "",
    "",
    [''],
    chartMassLabels, //"x_axis"
    chartMass,
  )
  
  const bgColors = [
    "#0dddcd", "#856DF0"
  ]
  const bgHoverColors = [
    "#2FEBD3", "#978FF3"
  ]
  const chartTerminalOptions = chartProp.options.axisTitleXY(words.chartTerminal.x, words.chartTerminal.y,true)
  const chartTerminalData = chartProp.data.carateMulti(
    "",
    "",
    chartTerminalLabels, //"x_axis"
    [chartProp.data.singleDataset(
      texts.terminal_labels[0], chartTerminalN, bgColors[0], bgHoverColors[0] //"pos_value"
    ), chartProp.data.singleDataset(
      texts.terminal_labels[1], chartTerminalC, bgColors[1], bgHoverColors[1] //"neg_value"
    ),]
  )
  
  const chartCompositionOptions = chartProp.options.axisTitleXY(words.chartComposition.x, words.chartComposition.y)
  const chartCompositionData = chartProp.data.carate(
    "",
    "",
    [''],
    chartCompositionLabels, //"x_axis"
    chartComposition,
  )
  
  //------------------------------------------------------------------------------
  const chartMethod = jsonsChart.method
  const [selectChartIndex, setSelectChartIndex] = useState()
  const [selectChartMethod, setSelectChartMethod] = useState()
  const [selectChartOptions, setSelectChartOptions] = useState(chartLengthOptions)
  const [selectChartData, setSelectChartData] = useState(chartLengthData)
  const [selectChartY, setSelectChartY] = useState(words.chartLength.y)
  const [selectBlockTitle, setSelectBlockTitle] = useState(words.chartLength.title)
  const [showTableLabel, setShowTableLabel] = useState(false)
  
  function handleClickMethod(index) {
    setSelectChartIndex(index)
    let method = index+1
    setSelectChartMethod(method)
    // console.log('index', index);
    // console.log('method', method);
    
    switch(method) {
      case chartMethod.Mass:
        setSelectChartOptions(chartMassOptions)
        setSelectChartData(chartMassData)
        setSelectChartY(words.chartMass.y)
        setSelectBlockTitle(words.chartMass.title)
        setShowTableLabel(false)
        break
      case chartMethod.Terminal:
        setSelectChartOptions(chartTerminalOptions)
        setSelectChartData(chartTerminalData)
        setSelectChartY(words.chartTerminal.y)
        setSelectBlockTitle(words.chartTerminal.title)
        setShowTableLabel(true)
        break
      case chartMethod.Composition:
        setSelectChartOptions(chartCompositionOptions)
        setSelectChartData(chartCompositionData)
        setSelectChartY(words.chartComposition.y)
        setSelectBlockTitle(words.chartComposition.title)
        setShowTableLabel(false)
        break
      case chartMethod.Length:
      default:
        setSelectChartOptions(chartLengthOptions)
        setSelectChartData(chartLengthData)
        setSelectChartY(words.chartLength.y)
        setSelectBlockTitle(words.chartLength.title)
        setShowTableLabel(false)
        break
    }
  }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiTableResult()
    apiChartResult(jsonsChart.method.Length)
    apiChartResult(jsonsChart.method.Mass)
    apiChartResult(jsonsChart.method.Terminal)
    apiChartResult(jsonsChart.method.Composition)
  }, [])
  
  useEffect(() => {
    //setup init chart data with chartMethod.Length
    handleClickMethod(0)
  }, [chartLengthLabels]) //after get any chartMethod.Length data
  
  //----render-----------------------------------------------------------------
  return (
    <LayoutPageV2 bread={words.bread} desc={words.intro}>
      
        <div className={scssACEiPP.frame_intro}>
          <TagText className={'Contents-QuanticoBody16px-Medium-White_75'} html={words.intro}></TagText>
        </div>
        
        <MuiTableContainer>
          <TableHeadRow> {/* title */}
            {texts.tableHeadCell.map((title, index) => (
              <MuiTableCell4Head key={index} index={index} align={index<=1 ? 'left' : 'center'}>{title}</MuiTableCell4Head>
            ))}
          </TableHeadRow>
          <TableBody>{/* value */}
            {tableItems.map((item, index) => (
              <TableRow2Body key={index}>
                <TableBodyCell key={index} index={index} tableBodyCell={texts.tableBodyCell}>
                  {item}
                </TableBodyCell>
              </TableRow2Body>
            ))}
          </TableBody>
        </MuiTableContainer>
        
        <Space />
        <Space />
        
        <div className={scss.chart_type_btns}>
          {chartDataBtnList.map((name, index) => {
              return (
                <GreenButton
                  key={index}
                  index={index}
                  onClick={() => {handleClickMethod(index)}}
                >{name}</GreenButton>)
            })
          }
        </div>
        
        {state.isLoading ? <LoadingAnime />
        : <ChartSection
            sectionTitle={words.chartTitle}
            chartBlockTitle={selectBlockTitle}
            chartBlockSubTitle={chartTextResult}
            mainChart={
              <BarChart
                data={selectChartData}
                options={selectChartOptions}
                showTableLabel={showTableLabel}
                yAxisLabel={selectChartY}
              />
            } />
        }
        
    </LayoutPageV2 >
  )
}
export default ToolACEiPPAnOxPPPrePool