import PropTypes from 'prop-types';

import scss from 'views/peptide/PeptideDFBPIDResult.module.scss';

//---------------------------------------------------------------------------
const MuiTabPanel = (props) => {
  const { children, value, index, isShownAllTabs, tabRef, ...other } = props;
  // console.log(props);
  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={scss.table_panel}
      hidden={isShownAllTabs ? false : value !== index}
      ref={isShownAllTabs ? (e => tabRef.current[index] = e) : null}
      {...other}
    >
      {isShownAllTabs
        ? children
        : value === index && (children)
      }
    </div>
  );
}
MuiTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function scrollTab(ref, index) {
  ref.current[index].scrollIntoView({ behavior: 'auto' })
}
scrollTab.propTypes = {
  ref: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

function handleChangeTab(event, newValue, setTabValue, isShownAllTabs, tabRef) {
  // console.log('newValue', newValue);
  setTabValue(newValue);
  if (isShownAllTabs)
    scrollTab(tabRef, newValue)
};

export { scrollTab, handleChangeTab }
export default MuiTabPanel