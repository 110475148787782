
import { breadData } from 'components/bread/BreadBarV2';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import { apiConfig } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';

//---------------------------------------------------------------------------
// http://localhost:3000/protein_frag_count?id=
// Go in: 'Protein' page -> any card button -> 'Fragment Count' button -> this page
// path: paths.spp.protein.fragment_count(),
const jsonsCount = spp.protein.search.fragment_count
const ProteinFragmentCount = ({ setInfo }) => {
  // const [searchParams] = useSearchParams()
  // const id = searchParams.get(paths.params.id)
  const classGraphSelectedSourceName = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceName)
  const classGraphSelectedSourceID = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceID)
  const classGraphSelectedClsName = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsName)
  const classGraphSelectedClsID = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsID)


  let breadTitle = '...'
  let input = []
  let class_code = 0
  let source_code = 0

  if (classGraphSelectedSourceName !== "") {
    breadTitle = classGraphSelectedSourceName
  } else {
    // Class Name.
    // Ex. Milk, Plant ...
    breadTitle = classGraphSelectedClsName
  }

  if (classGraphSelectedClsID !== "") {
    class_code = Number(classGraphSelectedClsID)

    input.push(class_code)
  }else{
    input.push("")
  }

  if (classGraphSelectedSourceID !== "") {
    source_code = Number(classGraphSelectedSourceID)
    input.push(source_code)
  }else{
    input.push("")
  }

  let texts = {
    bread: breadData.create('Protein', breadTitle + ' proteins'),

    searchHint: 'Search by SPP ID, Organism, Protein name, Protein length, UniProtKB',
    tableHeadCell: jsonsCount.output_text,
    tableBodyCell: jsonsCount.output,
  }

  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={apiConfig.protein.fragment_count}
      jsonInput={jsonsCount.input}
      jsonItems={jsonsCount.output}
      jsonInfo={jsonsCount.info}
      inputProp={input}
      jsonInputKeyword={jsonsCount.input_keyword}
    />
  )
}
export default ProteinFragmentCount