import { Link } from 'react-router-dom';


//---------------------------------------------------------------------------
export const Code = (props) => (
  <code style={{ fontSize: 14, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }} {...props}>
    {props.children}
  </code>
)

export const TagText = (props) => {
  const {html, ...other} = props
  return (<span dangerouslySetInnerHTML={{ __html: html }} {...other} style={{wordBreak:'break-word'}}/>)
}

export const TagTextWithScrollBar = (props) => {
  const {html, ...other} = props
  return (<div style={{display: 'block',
  maxHeight: '450px',
  overflowY: 'auto',
  paddingRight: '12px',
  backgroundColor:"var(--white-white-75)",
  minHeight: '36px'}} dangerouslySetInnerHTML={{ __html: html }} {...other} />)
}

//---------------------------------------------------------------------------
const Text = (props) => {
  const {center, right, pre, ...other} = props
  let styles = { whiteSpace: 'pre-line', textAlign: 'left'
  // , background: '#00ff00'
  }
  if (center !== undefined)
    Object.assign(styles, {textAlign: "center"})
  if (right !== undefined)
    Object.assign(styles, {textAlign: "right"})
  if (pre !== undefined)
    Object.assign(styles, {whiteSpace: 'pre'})
  if (props.fontSize !== undefined)
    Object.assign(styles, {fontSize: `${props.fontSize}px`})
  if (props.style !== undefined) //at last add
    Object.assign(styles, props.style)
  return (
    <span style={styles} {...other}>
      {props.children}
    </span>)
}

const TextLink = (props) => {
  const {link, ...other} = props
  const isUrl = (link !== undefined) && (link.url !== null) && (link.url !== '')
  if(isUrl)
    return (
      <Link to={link.url}>
        <Text {...other}>
          {link.text}
        </Text>
      </Link>)
  else
    return (
      <Text {...other}>
        {link ? link.text : props.children}
      </Text>)
}

export { TextLink }
export default Text;