import { Box } from "@mui/material";

export const invalidChar = ["B", "J", "O", "U", "X", "Z"];
export function checkInvalidSeq(value) {
  const invalidMsg = `Cannot include ${invalidChar.join(",")}`;
  const valueUpper = value.toUpperCase()
  let valueFix = valueUpper
  valueFix = String(valueFix).removeAll('SEQUENCE') //for PPSP '>Sequence01'
  valueFix = String(valueFix).removeAll('DOCKING') //for PPD '>Docking01'
  // console.log(valueFix);
  const isInvalid = invalidChar.some((char) =>
    valueFix.includes(char)
  );

  return { isInvalid: isInvalid, errMsg: isInvalid ? invalidMsg : "" };
}

export function isOnlySpacesOrNewlines(input) {
  const invalidMsg = `Invalid Value`;

  const isInvalid = /^ *$/.test(input) || /^[\r\n]*$/.test(input);

  return { isInvalid: isInvalid, errMsg: isInvalid ? invalidMsg : "" };
}

export function isFASTAformat(input, charsPass = '') {
  const invalidMsg = `Invalid FASTA format`;

  const escapedChars = charsPass.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(
    `^>[^\n]+(?:\n[^\n]+)*\n[ACDEFGHIKLMNPQRSTVWYacdefghiklmnpqrstvwy${escapedChars}]+(?:\n[ACDEFGHIKLMNPQRSTVWYacdefghiklmnpqrstvwy${escapedChars}]+)*$`
  );
  const isValid = regex.test(input);
  const isInvalid = !isValid
  // console.log(isValid, input)
  // console.log('isInvalid', isInvalid);

  return { isInvalid: isInvalid, errMsg: isInvalid ? invalidMsg : "" };
}

export function getMiddleIndex(arr) {
  const length = arr.length;

  if (length === 0) return -1;

  if (length === 1) return 1;

  if (length % 2 !== 0) {
    return Math.floor(length / 2);
  } else {
    return length / 2;
  }
}

export const Space = () => <Box sx={{ mt: 2 }} />;

export const determineHasEmptyListInTwoLevelObj = (response) => {
  // two level obj
  let hasEmptyArray = false;

  for (const outerKey of Object.keys(response)) {
    const innerObj = response[outerKey];

    for (const innerKey of Object.keys(innerObj)) {
      if (
        Array.isArray(innerObj[innerKey]) &&
        innerObj[innerKey].length === 0
      ) {
        hasEmptyArray = true;
        break;
      }
    }

    if (hasEmptyArray) {
      break;
    }
  }

  return hasEmptyArray;
};

export const isEmptyObjOrArray = (obj) => {
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).length === 0;
  }

  if (obj === null) {
    return true;
  }

  if (obj === undefined) {
    return true;
  }
  return false;
};

// https://stackoverflow.com/questions/73577234/react-router-6-navigate-to-new-tab-with-state
export const navigateNewTab = (target) => {
  if( target !== undefined && target !== '' )
    window.open(target, "_blank", "noreferrer");
}

export function getFullPath(location) {
  if( Object.isObject(location) ) {
    // console.log('location', location)
    if( location.pathname !== undefined )
      return location.pathname + location.search
  }
  return ''
}


export function indexData(start, end) {
  return { start, end }
}
export function getIndexArray(inputLength, rawLength) {
  let index = []
  for (const i of Array(Math.ceil(inputLength / rawLength)).keys())
    index.push(indexData(i*rawLength, (i*rawLength)+rawLength-1))
  return index
}

export function firstDigitToZeros(num) {
  const str = num.toString();
  const result = str[0] + '0'.repeat(str.length - 1);
  return parseInt(result, 10);
}