import React from 'react';
import scss from './GradientToolCard.module.scss';

const GradientToolCard = ({ children, onClick, ...props }) => {
  const { disabled } = props
  
  return (
    <div className={disabled ? scss.gradient_card_disabled : scss.gradient_card}
      onClick={onClick}>

{/* 
<svg width="330" height="330" viewBox="0 0 330 330" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.3994 321.355V265.537C25.6647 264.237 27.4587 263.216 29.5341 262.029V190.325L23.3994 186.817V27.157L58.4628 7.104H167.944L177.238 12.418H296.323L305.616 7.104H314.888V49.9254L299.531 58.7069V120.439L314.888 129.212V289.46L259.099 321.355H23.3994ZM312.208 130.081L299.46 122.792V126.071L312.208 133.364V130.081ZM312.208 135.713L299.46 128.424V131.702L312.208 138.991V135.713ZM312.208 141.345L299.46 134.052V137.334L312.208 144.623V141.345ZM312.208 146.976L299.46 139.687V142.966L312.208 150.255V146.976ZM312.208 152.608L299.46 145.315V148.598L312.208 155.887V152.608ZM312.208 158.24L299.46 150.951V154.229L312.208 161.522V158.24Z" fill="url(#paint0_linear_4684_2866)"/>
<path d="M326.728 52.5923V18.1743H323.598V51.8521L307.543 61.0319V116.082H310.674V61.772L326.728 52.5923Z" fill="url(#paint1_linear_4684_2866)"/>
<path d="M9.22998 317.095V314.114H14.4431V317.095H9.22998Z" fill="url(#paint2_linear_4684_2866)"/>
<path d="M9.22998 311.049V308.068H14.4431V311.049H9.22998Z" fill="url(#paint3_linear_4684_2866)"/>
<path d="M9.22998 305.007V302.026H14.4431V305.007H9.22998Z" fill="url(#paint4_linear_4684_2866)"/>
<path d="M9.22998 298.965V295.984H14.4431V298.965H9.22998Z" fill="url(#paint5_linear_4684_2866)"/>
<path d="M9.22998 292.919V289.938H14.4431V292.919H9.22998Z" fill="url(#paint6_linear_4684_2866)"/>
<path d="M9.22998 323.137V320.156H14.4431V323.137H9.22998Z" fill="url(#paint7_linear_4684_2866)"/>
<path d="M163.498 329.111H149.808L146.677 327.321H163.498L165.053 326.432H263.546L323.767 292.006V235.825L325.315 234.936V226.175L328.445 227.965V234.924L330 235.813V293.482L323.767 297.046L269.448 328.089L266.114 330H165.053L163.498 329.111Z" fill="url(#paint8_linear_4684_2866)"/>
<path d="M299.46 122.792L312.208 130.085V133.364L299.46 126.07V122.792Z" fill="url(#paint9_linear_4684_2866)"/>
<path d="M299.46 128.42L312.208 135.709V138.987L299.46 131.698V128.42Z" fill="url(#paint10_linear_4684_2866)"/>
<path d="M299.46 134.043L312.208 141.337V144.615L299.46 137.326V134.043Z" fill="url(#paint11_linear_4684_2866)"/>
<path d="M299.46 139.671L312.208 146.96V150.243L299.46 142.95V139.671Z" fill="url(#paint12_linear_4684_2866)"/>
<path d="M299.46 145.295L312.208 152.588V155.867L299.46 148.577V145.295Z" fill="url(#paint13_linear_4684_2866)"/>
<path d="M299.46 150.923L312.208 158.212V161.494L299.46 154.201V150.923Z" fill="url(#paint14_linear_4684_2866)"/>
<path d="M0 149.539V147.749H3.13769V149.539H0Z" fill="url(#paint15_linear_4684_2866)"/>
<path d="M0 153.131V151.341H3.13769V153.131H0Z" fill="url(#paint16_linear_4684_2866)"/>
<path d="M0 156.719V154.929H3.13769V156.719H0Z" fill="url(#paint17_linear_4684_2866)"/>
<path d="M0 160.311V158.521H3.13769V160.311H0Z" fill="url(#paint18_linear_4684_2866)"/>
<path d="M0 163.903V162.113H3.13769V163.903H0Z" fill="url(#paint19_linear_4684_2866)"/>
<path d="M20.2684 230.097C16.9407 228.198 14.6402 226.879 10.9749 224.783V93.4708C14.6613 91.3589 16.9407 90.0556 20.2684 88.1569V49.8168L13.402 53.7429V85.7755L3.13769 91.6445V101.701L10.9397 97.2401V99.7382L3.13769 104.187V106.553L10.9397 102.087V104.59L3.13769 109.067V145.955H0V90.9124L10.2714 85.0353V50.9874L20.2402 45.2873V26.4129L57.1679 5.31395H77.8444C81.165 3.41525 83.4725 2.09581 87.1378 0H206.222L215.516 5.31395H317.991V50.6857L302.64 59.4672V119.699L317.991 128.472V290.184L260.372 323.129H20.2684V230.097ZM259.099 321.355L314.888 289.46V129.212L299.53 120.439V58.7069L314.888 49.9254V7.10404H305.616L296.322 12.418H177.238L167.944 7.10404H58.4624L23.399 27.1571V186.817L29.5478 190.325V262.029C27.4724 263.236 25.6784 264.237 23.4131 265.537V321.355H259.099Z" fill="url(#paint20_linear_4684_2866)"/>
<defs>
<linearGradient id="paint0_linear_4684_2866" x1="23.3994" y1="164.23" x2="314.888" y2="164.23" gradientUnits="userSpaceOnUse">
<stop stop-color="#64FFDA" stop-opacity="0.08"/>
<stop offset="1" stop-color="#64FFDA" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_4684_2866" x1="307.543" y1="67.1282" x2="326.728" y2="67.1282" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint2_linear_4684_2866" x1="9.22998" y1="315.605" x2="14.4431" y2="315.605" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint3_linear_4684_2866" x1="9.22998" y1="309.559" x2="14.4431" y2="309.559" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint4_linear_4684_2866" x1="9.22998" y1="303.517" x2="14.4431" y2="303.517" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint5_linear_4684_2866" x1="9.22998" y1="297.475" x2="14.4431" y2="297.475" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint6_linear_4684_2866" x1="9.22998" y1="291.428" x2="14.4431" y2="291.428" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint7_linear_4684_2866" x1="9.22998" y1="321.647" x2="14.4431" y2="321.647" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint8_linear_4684_2866" x1="146.677" y1="278.087" x2="330" y2="278.087" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint9_linear_4684_2866" x1="299.46" y1="128.078" x2="312.208" y2="128.078" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint10_linear_4684_2866" x1="299.46" y1="133.704" x2="312.208" y2="133.704" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint11_linear_4684_2866" x1="299.46" y1="139.329" x2="312.208" y2="139.329" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint12_linear_4684_2866" x1="299.46" y1="144.957" x2="312.208" y2="144.957" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint13_linear_4684_2866" x1="299.46" y1="150.581" x2="312.208" y2="150.581" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint14_linear_4684_2866" x1="299.46" y1="156.209" x2="312.208" y2="156.209" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint15_linear_4684_2866" x1="0" y1="148.644" x2="3.13769" y2="148.644" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint16_linear_4684_2866" x1="0" y1="152.236" x2="3.13769" y2="152.236" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint17_linear_4684_2866" x1="0" y1="155.824" x2="3.13769" y2="155.824" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint18_linear_4684_2866" x1="0" y1="159.416" x2="3.13769" y2="159.416" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint19_linear_4684_2866" x1="0" y1="163.008" x2="3.13769" y2="163.008" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
<linearGradient id="paint20_linear_4684_2866" x1="0" y1="161.565" x2="317.991" y2="161.565" gradientUnits="userSpaceOnUse">
<stop stop-color="#2BFFFF" stop-opacity="0.32"/>
<stop offset="0.5" stop-color="#2BFFFF" stop-opacity="0.1"/>
<stop offset="1" stop-color="#2BFFFF" stop-opacity="0.16"/>
</linearGradient>
</defs>
</svg> */}


    <div className={scss.card_content}>
      {children}
    </div>
  </div>

);
};

export default GradientToolCard;