import LoadingButton from '@mui/lab/LoadingButton';

import scssButton from 'components/button/Buttons.module.scss'
//------------------------------------------------------------------------------
export default function MuiLoadingButton(props) {
  const { children, ...rest } = props;
  
  return (
    <LoadingButton
      className={scssButton.button}
      sx={{
        '& .MuiLoadingButton-loadingIndicator': {
          color: 'white',},
        '&.Mui-loading': {
          color: 'white',
          backgroundColor: 'var(--green-alpha-alpha-8)',
          '& .MuiButton-startIcon': {
            color: 'white'
          }},
        '&.Mui-disabled': {
          color: 'grey',
          opacity: 0.7},
      }}
      {...rest}
    >{children}</LoadingButton>
  )
}