import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TableBody from '@mui/material/TableBody';
import { FormControlLabel, TableHead, TableRow } from '@mui/material';

import scssTRL from './ToolResultLayout.module.scss'
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import { inits, itemNoData, pageNoData, getNewPageNo, tableSource } from 'layouts/search/tableData';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import SearchResultTable from './SearchResultTable';
import { getFullPath, isEmptyObjOrArray } from 'utils/general';
import MuiChip from 'components/mui/MuiChip';
import { MuiTableHeadCell, getClassifyIndexArr, getOptions } from 'views/tool/ToolHotSpotSearch';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import {MuiTableContainerNoneBorder, TableRow2Body } from 'components/table/MuiTableV2';
import StyledTableCell from 'components/table/StyledTableCell';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GreenButton from 'components/button/GreenButton';

//------------------------------------------------------------------------------
// for
// http://localhost:3000/tool_aceipp_anoxpp_mp?&dataset=1&seq=IHPF

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

//---------------------------------------------------------------------------
const ToolResultLayout = (props) => {
  let { tableBodyCell, setInfo } = props
  let texts = props.texts
  let dbItems = props.jsonItems ? props.jsonItems : spp.common.output.items
  if ( tableBodyCell === undefined )
    tableBodyCell = texts.tableBodyCell
  // console.log('tableBodyCell', tableBodyCell);
  // console.log(dbItems);
  
  let location = useLocation()
  let fullPath = getFullPath(location)
  const source = new tableSource(tableBodyCell)
  
  const [inputProp, setInputProp] = useState(props.inputProp)
  // console.log('props.inputProp', props.inputProp);
  // console.log('inputProp', inputProp);
  
  let isSearchFragment = props.isSearchFragment
  // console.log('isSearchFragment', isSearchFragment);
  
  const [output, setOutput] = useState({})
  const [items, setItems] = useState([])
  const [itemNo, setItemNo] = useState(itemNoData.init()) //item index 1-10
  const [itemsPerPage, setItemsPerPage] = useState(inits.ItemNum) //item number per page
  const eventChangeItemsPerPage = (value) => {
    if (value !== undefined) {
      setItemsPerPage(value)
      setPageNo(pageNoData.init())
      if (keyword !== '') {
        setInput(props.jsonInputKeyword(...inputProp, keyword, value, 1))
      } else {
        setInput(props.jsonInput(...inputProp, value, 1))
      }
      setState(axiosState.init())
    }
  }

  let words = {
    index: [`${itemNo.start}-${itemNo.end}`, `of ${output.total}`],
    page: ['Previous', '...', 'Next'],
    goto: 'Go to',
    filter: 'Filter',
    
    dialog: {
      title: 'Select protein source(s)',
      ok: 'OK',
      buttons: [
        'Check All',
        'Uncheck All',
      ],
    },
  }
  
  const [pageNo, setPageNo] = useState(pageNoData.init())
  const [pages, setPages] = useState([`${pageNo.now}`, ...words.page])
  const handleClickPage = (value) => {
    // console.log(value);
    let pageNew = getNewPageNo(pageNo, value)
    if(!pageNew)
      return;
    
    // console.log('pageNew', pageNew)
    setPageNo(pageNoData.create(pageNew, pageNo.last))

    if (keyword !== '')
      setInput(props.jsonInputKeyword(...inputProp, keyword, itemsPerPage, pageNew))
    else
      setInput(props.jsonInput(...inputProp, itemsPerPage, pageNew))
    setState(axiosState.init())
  }

  const [keyword, setKeyword] = useState('')
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
  }
  const handleClickSearch = () => {
    if (keyword !== '') {
      setInput(props.jsonInputKeyword(...inputProp, keyword, itemsPerPage))
    }
    else {
      setInput(props.jsonInput(...inputProp, itemsPerPage))
    }
    setState(axiosState.init())
  }

  const [input, setInput] = useState(props.jsonInput(...inputProp, itemsPerPage))

  function removeEmptyStringKeys(obj) {
    // console.log("obj:", obj)
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== "" && value !== 0)
    );
  }
  
  const [others, setOthers] = useState([])
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiResult = () => {
    let newInput = removeEmptyStringKeys(input)
    // console.log('input', input);
    // console.log('newInput', newInput);
    
    const config = props.configSearch(newInput)
    // console.log('config', config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data
        setOutput(data)
        // console.log(data)
        if(source.isToolManualPeptide) {
          let total = spp.tool.hot_spot_result_chart.output[2] //"Total protein",
          data.total = data[total]
        }
        
        let item = result.data[dbItems]
        let count = (item && item.length) || 0
        let total = data.total
        
        setItems(item)
        // console.log('item', item)
        // console.log('count', count);
        // console.log('pageNo.now', pageNo.now);
        
        let itemNoNow = itemNoData.get(pageNo.now, itemsPerPage, count)
        setItemNo(itemNoNow)
        // console.log(itemNoNow);
        let pagesNew = pageNoData.getPages(pageNo.now, itemsPerPage, total)
        let pageNoNew = pageNoData.get(pageNo.now, itemsPerPage, total)
        setPages(pagesNew)
        setPageNo(pageNoNew)
        // console.log(pagesNew);
        // console.log(pageNoNew);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //------------------------------------------------------------------------------
  const jsonList = spp.protein.list
  const colSize = 3
  const [sourceFilter, setSourceFilter] = useState([])
  const [classifyFilter, setClassifyFilter] = useState([])
  const [sourceList, setSourceList] = useState([])
  const [classifyList, setClassifyList] = useState([])
  const [classifyIndex, setClassifyIndex] = useState([])
  const [stateList, setStateList] = useState(axiosState.init())
  const apiList = () => {
    const config = apiConfig.protein.list() //10
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        // console.log(result.data);
        
        // setOutput(result.data)
        let item = result.data[spp.common.output.items]
        // console.log('items', item);
        
        let source
        if( !isEmptyObjOrArray(item) )
          source = item.map((item) => ({...item, isCheck: true, isDisable: false}))
        setSourceList(source)
        // console.log('source', source);
        /* {
          classification: "Milk"
          classification_id: "110000"
          counts: 43
          isCheck: true
          isDisable: false
          source: "Cow milk"
          source_id: "110100" }*/
        
        let classify = getOptions(source, jsonList.items.class, jsonList.items.class_id)
        if( !isEmptyObjOrArray(classify) )
          classify = classify.map((item) => ({...item, isCheck: true, isDisable: false}))
        setClassifyList(classify)
        // console.log('classify', classify);
        /* {
          isCheck: true
          isDisable: false
          label: "Milk"
          value: "110000" } */
        
        let classIndex = getClassifyIndexArr(item, colSize)
        setClassifyIndex(classIndex)
        // console.log('classIndex', classIndex);
        /* [
        { start: 0, end: 2, label: "Milk" }
        { start: 3, end: 5, label: "Milk" }
        { start: 6, end: 8, label: "Milk" }
        { start: 9, end: 9, label: "Milk" } ] */
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  //------------------------------------------------------------------------------
  // Dialog
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  
  const handleChangeClassify = (id, prevClassify) => {
    let bCheck
    setClassifyList(prevItems => prevItems.map((item) => {
      if(item.value === id) {
        bCheck = !item.isCheck
        // console.log('bCheck', bCheck);
        setSourceList(prevItems => prevItems.map((item) => {
          if(item.classification_id === id)
            return { ...item, isCheck: bCheck };
          return item
        }))
        
        return { ...item, isCheck: bCheck };
      }
      return item
    }))
  }
  
  const handleChangeSource = (id, prevSource) => {
    // console.log(prevSource);
    let bCheck, class_id
    setSourceList(prevItems => prevItems.map((item) => {
      if(item.source_id === id) {
        bCheck = !item.isCheck
        class_id = item.classification_id
        return { ...item, isCheck: bCheck };
      }
      return item
    }))
        
    let bCheckClass = bCheck
    if(bCheck) {      
      let total = 0, checked = 0
      prevSource.forEach((item) => {
        if(item.classification_id === class_id) {
          total++
          if(item.isCheck)
            checked++
      }})
      // console.log('class_id', class_id, '=', total, 'v=', checked);
      if( total > 0 && total - checked <= 1 )
        bCheckClass = true
      else
        bCheckClass = false
    }
    
    if( bCheckClass !== undefined )
      setClassifyList(prevItems => prevItems.map((item) => {
          if(item.value === class_id)
            return { ...item, isCheck: bCheckClass };
          return item
      }))
  }
  
  const handleClickOption = (value) => {
    let bCheck
    switch (value) {
      case words.dialog.buttons[0]: //'Check All'
        bCheck = true
        break
      case words.dialog.buttons[1]: //'Uncheck All',
        bCheck = false
        break
      default:
    }
    
    setClassifyList(prevItems => prevItems.map((item) => ({ ...item,
      isCheck: bCheck,
    })))
    setSourceList(prevItems => prevItems.map((item) => ({...item, 
      isCheck: bCheck,
    })))
  }
  
  const handleClickOK = () => {
    setOpen(false)
    changeFilter()
  }
  
  // clsChangeId, srcChangeId using on onDeleteXXX function
  function changeFilter(clsChangeId = null, srcChangeId = null) {
    const clsOrg = classifyList.filter(item => item.isCheck)
    const cls = (clsChangeId !== null)
    ? clsOrg.filter(item => item.value !== clsChangeId)
    : clsOrg
    const clsLength = cls.length
    // console.log('cls', clsLength, cls);
    if( clsLength === 0 || clsLength === classifyList.length )
      setClassifyFilter([])
    else
      setClassifyFilter(cls)
    
    const srcOrg = sourceList.filter(item => item.isCheck)
    const src2nd = (clsChangeId !== null)
    ? srcOrg.filter(item => item[jsonList.items.class_id] !== clsChangeId)
    : srcOrg
    const src = (srcChangeId !== null)
    ? src2nd.filter(item => item[jsonList.items.source_id] !== srcChangeId)
    : src2nd
    const srcLength = src.length
    console.log('src', srcLength, src);
    if( srcLength === 0 || srcLength === sourceList.length ) {
      setSourceFilter([])
      
      inputProp[1] = []
      inputProp[2] = true
    } else { //srcLength>0 && srcLength!=sourceList.length
      if( clsLength > 0 ) { // remove source in classify group selected
        let clsId = cls.map(i => Number(i.value))
        // console.log(clsId);
        let srcFt = []
        src.forEach(i => {
          let class_id = i[jsonList.items.class_id]
          // console.log(i);
          // console.log(class_id);
          if(!clsId.includes(Number(class_id))) {
            // console.log(i);
            srcFt.push(i)
          }
        })
        
        // console.log('srcFt', srcFt);
        setSourceFilter(srcFt)
      } else {
        setSourceFilter(src)
      }
      
      let srcId = src.map(item => item.source_id)
      // console.log('listId', listId);
      inputProp[1] = srcId
      inputProp[2] = false
    }
    // console.log('inputProp', inputProp);
    setInputProp(inputProp)
    handleClickSearch()
  }
  
  const onDeleteClassify = (id, prevClassify) => {
    handleChangeClassify(id, prevClassify)
    //classifyList did not immediately refresh
    changeFilter(id, null)
  }
  
  const onDeleteSource = (id, prevSource) => {
    handleChangeSource(id, prevSource)
    //sourceList did not immediately refresh
    changeFilter(null, id)
  }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    if (axiosState.keepRest(stateList))
      apiList()
    if (axiosState.keepRest(state))
      apiResult()
    // eslint-disable-next-line
  }, [input, inputProp, pageNo.now])
  
  let style = {}
  if( props.desc !== undefined )
    style = { marginTop: "200px" }
    
  return (
    <LayoutPageV2 bread={texts.bread}
      desc={props.desc !== undefined ? props.desc : ""}
      searchInput={inputProp} jsonInput={props.jsonInput}
      searchOutput={output}>
      <div className={scssTRL.layout} style={style}>
        
        {/* filter bar */}
        <div className={scssTRL.frame_filter}>
          <GreenButton //Filter
            onClick={handleClickOpen}
          >
            {words.filter}
          </GreenButton>
          <div className={scssTRL.filter_tag}>
            {classifyFilter.map((item, index) => {
              return (
                <MuiChip label={item.label} onDelete={() => onDeleteClassify(item.value, classifyList)} />)
            })}
            {sourceFilter.map((item, index) => {
              return (
                <MuiChip label={item.source} onDelete={() => onDeleteSource(item.source_id, sourceList)} />)
            })}
          </div>
        </div>
        
        {/* Dialog */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor:'#030909', 
              color: 'white'
            }
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2,backgroundColor:'#2D574A',fontFamily:'Audiowide',color:'white' }} id="customized-dialog-title" >
            {words.dialog.title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[200],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
          {classifyList.map((option, idx) => (
            <MuiTableContainerNoneBorder key={option.value}>
              <MuiTableHeadCell key={option.value} colSpan={colSize}>
                <FormControlLabel
                sx={{
                  color: 'var(--white-white-75)', whiteSpace: 'pre-line',
                  '& .MuiFormControlLabel-label': {
                    fontFamily: 'Quantico',
                    fontSize: '20px'
                  },
                  '& .Mui-checked + .MuiFormControlLabel-label': {
                    fontWeight: '600',
                  }
                }} 
                  key={`form-${idx}`}
                  control={
                    <MuiCheckbox
                      size="small"
                      key={option.value}
                      name={option.label}
                      value={option.value}
                      checked={option.isCheck}
                      onChange={() => handleChangeClassify(option.value, classifyList)}
                      inputProps={{ 'aria-label': 'controlled' }} />}
                  label={
                    <span className={option.isDisable ? 'Contents-QuanticoBody20px-Regular' : 'Contents-QuanticoBody20px-Regular'}>{option.label}</span>}
                />
                </MuiTableHeadCell>

              <TableBody key={option.label} sx={{ border: 0 }}>
                {classifyIndex.map((value, count) => {
                  //console.log('value:', value, 'count:', count);
                  if (value.label === option.label) {
                    return (
                      <TableRow2Body key={`body-${count}`} style={{ border: 0 }} sx={{ border: 0 }}>
                        { // slice end position start index from 1
                          sourceList.slice(value.start, value.end + 1).map((item, index) => {
                            // console.log('start:', value.start, 'index:', index);
                            let idx = value.start + index
                            return (
                              <StyledTableCell key={`cell-${count}-${index}`}
                                sx={index === 0 ? { padding: 0, paddingLeft: 2, border: 0, width: 280 } : { padding: 0, border: 0, width: 240 }} >
                                <FormControlLabel
                                sx={{
                                  color: 'var(--white-white-75)', whiteSpace: 'pre-line',
                                  '& .MuiFormControlLabel-label': {
                                    fontFamily: 'Quantico',
                                    fontSize: '16px'
                                  },
                                  '& .Mui-checked + .MuiFormControlLabel-label': {
                                    fontWeight: '600',
                                  }
                                }} 
                                  key={`form-${count}-${index}`}
                                  style={{ whiteSpace: 'pre-line' }}
                                  control={
                                    <MuiCheckbox
                                      key={item.source_id}
                                      name={item.source}
                                      value={idx}
                                      checked={item.isCheck}
                                      onChange={() => handleChangeSource(item.source_id, sourceList)}
                                      inputProps={{ 'aria-label': 'controlled' }} />}
                                  label={`${(item.source).trimClassifyName()}`}
                                />
                              </StyledTableCell>)
                        })}
                      </TableRow2Body>
                  )}
                  return null
                })}
              </TableBody>
            </MuiTableContainerNoneBorder>
          ))}
          </DialogContent>
          <DialogActions>
            {words.dialog.buttons.map((item, index) => (
              <GreenButton
                key={index}
                index={index}
                onClick={() => handleClickOption(item)}
              >{item}</GreenButton>
            ))}
            <GreenButton
              autoFocus onClick={handleClickOK}>
              {words.dialog.ok}
            </GreenButton>
          </DialogActions>
        </BootstrapDialog>
          
        {/* table */}
        <SearchResultTable
          tableHeadCell={texts.tableHeadCell}
          tableBodyCell={texts.tableBodyCell}
          isLoading={state.isLoading}
          output={output}
          inputProp={inputProp}
          info={props.jsonInfo}
          items={items}
          itemNo={itemNo}
          eventChangeItemsPerPage={eventChangeItemsPerPage}
          texts={texts}
          words={words}
          pages={pages}
          pageNo={pageNo}
          handleClickPage={handleClickPage}
          keyword={keyword}
          handleChangeSearch={handleChangeSearch}
          handleClickSearch={handleClickSearch}
          others={others}
          isSearchFragment={isSearchFragment}
        />
      </div>
    </LayoutPageV2>
  )
}
export default ToolResultLayout