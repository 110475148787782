// import React from 'react';
import { extractSets } from "chartjs-chart-venn";
import PropTypes from "prop-types";

import v from "assets/scss/_variables.scss";
import "assets/scss/_variablesV2.scss";
import "assets/scss/fonts_colorsV2.scss"


import paths from "utils/network/apiPath";
import cookie from "utils/cookie";

//---------------------------------------------------------------------------
class datasets {
  static create(label, data, color) {
    return {
      label: label,
      data: data,
      borderColor: color,
      backgroundColor: color,
      // hoverBackgroundColor: color,
    };
  }
}

class data {
  static carate(title, text, label, labels = [], data = [], 
    backgroundColor = [v.green], hoverBackgroundColor =[v.chartHoverGreen]) {
    return {
      title: title,
      text: text,
      labels: labels,
      datasets: [
        {
          label: label,
          data: data,
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor,
        },
      ],
    };
  }

  static carateMulti(title, text, labels, datasets) {
    return {
      title: title,
      text: text,
      labels: labels,
      datasets: datasets,
    };
  }

  static singleDataset(label, data, bgColor, hoverBgColor) {
    return {
      label: [label],
      data: data,
      backgroundColor: [bgColor],
      hoverBackgroundColor: [hoverBgColor],
    };
  }
  
  static carateVenn(label, values) {
    return {
      label,
      'values': values, //array
  }}
  
  static vennSets(sets) {
    return extractSets(sets)
  }
  
  static createRadar(title, indicator, series) {
    return {
      title,
      indicator,
      series,
  }}
  static radarIndicator(name, max) {
    return {
      name,
      max,
  }}
  static radarSeries(name, value = []) {
    return {
      name,
      value,
  }}
}

data.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
};
/* 
Labeling Axes | Chart.js
https://www.chartjs.org/docs/latest/axes/labelling.html
 */
class options {
  static axisTitleXY(textX, textY, showLegend = false) {
    return {
      layout: {
        padding: {
          top: 50,
          left: 20,
        },
      },
      scales: {
        x: {
          grid: {
            color: 'grey', 
            lineWidth: 0.5 
          },
          ticks: {
            color: 'white',
            font:{
              size:14,
              family: 'Quantico',
            }
          },
          title: {
            color: 'white',
            align: 'end', //start, center, end
            display: true,
            color: 'white',
            text: textX,
            font: {
              size: 14,
            },
          },
        },
        y: {
          grid: {
            color: 'grey', 
            lineWidth: 0.5 
          },
          ticks: {
            color: 'white',
            font:{
              size:14,
              family: 'Quantico',
            }
          },
          title: {
            // align: 'end', //start, center, end
            display: false,
          },
        },
      },
      animation: {},
      plugins: {
        legend: {
          display:showLegend,
          labels: {
            color: 'white',
          }
        }
      }
    };
  }

  static axisCountsXY(textX, textY) {
    return {
      layout: {
        padding: {
          top: 50,
          left: 20,
        },
      },
      scales: {
        x: {
          title: {
            // align: 'end', //start, center, end
            display: true,
            text: textX,
            font: {
              size: 14,
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            // make all scale be integer
            stepSize: 1,
            // avoid to use float
            precision: 0,
          },
          title: {
            // align: 'end', //start, center, end
            display: false,
            text: textY,
          },
        },
      },
      animation: {},
    };
  }

  static hotSpotResultClickable(textX, textY, labels, navigate, keyword) {
    return {
      layout: {
        padding: {
          top: 50,
          left: 20,
        },
      },
      scales: {
        x: {
          title: {
            // align: 'end', //start, center, end
            display: true,
            text: textX,
            font: {
              size: 14,
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            // make all scale be integer
            stepSize: 1,
            // avoid to use float
            precision: 0,
          },
          title: {
            // align: 'end', //start, center, end
            display: false,
            text: textY,
          },
        },
      },
      onClick: (event, elements) => {
        if (elements.length === 0) {
          return;
        }

        const sppId = labels[elements[0].index].split("--")[1];
        navigate(paths.spp.tool.hotspot_result(sppId, keyword));
      },
    };
  }

  static peptideCalResAxisTitleXY(textX, textY) {
    return {
      scales: {
        x: {
          ticks: {
            // Add 'pH=' to the x-axis values
            callback: function (value, index, values) {
              return "pH=" + value / 100;
            },
            color: '#f0fffb' 
          },
          title: {
            display: true,
            text: textX,
            color: 'white',
          },
        },
        y: {
          ticks: {
            // Add 'e' to the y-axis values
            callback: function (value, index, values) {
              return value + "e";
            },
            color: '#f0fffb' 
          },
          title: {
            display: true,
            text: textY,
            color: 'white',
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              return (
                context.dataset.label + ": " + context.formattedValue + "e"
              );
            },
            title: function (tooltipItems) {
              return "pH=" + tooltipItems[0].label;
            },
          },
        },
        legend: {
          labels: {
            color: 'white' 
          }
        }
      },
    };
  }
  
  static statisticProtein(textX, textY, sources, labels, index, navigate) {
    return {
      layout: {
        padding: {
          top: 50,
          left: 20,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: textX,
            font: {
              size: 14,
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            // make all scale be integer
            stepSize: 1,
            // avoid to use float
            precision: 0,
          },
          title: {
            // align: 'end', //start, center, end
            display: false,
            text: textY,
          },
        },
      },
      onClick: (event, elements) => {
        if (elements.length === 0) {
          return;
        }

        const id = sources[elements[0].index];
        const name = labels[elements[0].index];
        // console.log(id, sources);
        // console.log(name, labels);
        
        // 'Proteins'
        cookie.setCookie(cookie.keys.protein.classGraphSelectedClsID, "")
        cookie.setCookie(cookie.keys.protein.classGraphSelectedClsName, "")
        cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceID, id)
        cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceName, name)
        cookie.setCookie(cookie.keys.peptide.selectedMultiPeptideList, [])
        switch (index) {
          case 0:
            //'Protein',
            navigate(paths.spp.protein.source_search())
            break;
          case 1:
            // 'Peptide',
            // "Classification Graph" Class-Graph
            navigate(paths.spp.protein.class_graph())
            break;
          case 2:
            // 'Average',
            // "Fragment Graph" Frag-Graph
            navigate(paths.spp.protein.frag_graph())
            break;
        }
      },
    };
  }
}

const chartProp = {
  data,
  options,
  // plugins,
  datasets,
};
export default chartProp;
