

// import scss from './BreadText.module.scss';
import scss2 from './BreadTextV2.module.scss';
import Text from 'components/tag/Text';

//---------------------------------------------------------------------------
export default function BreadTextV2(props) {
  const { bread } = props
  
  const title = bread ? bread.title : '...'
  
  const fontSizeInit = 28
  const fontSize = getFontSize(title)
  function getFontSize(breadText) {
    const len = breadText.length
    let size = fontSizeInit
    
    if (len >= 52 && len < 59)
      size = 24
    else if (len >= 59)
      size = 22
    
    // console.log('length', len, 'fontSize', size);
    return size
  }

  //---------------------------------------------------------------------------
  return (
    <div className={scss2.frame}>
      <Text className='Title-Audiowide28px-Regular-Title' fontSize={fontSize}>
        {title}
      </Text>
    </div>)
};