import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'assets/scss/indexV2.scss'

//---------------------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily:'Quantico',
  fontSize: 16,
  whiteSpace: 'pre-line',
  color: 'var( --white-white-75)',
  border:0,
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    // fontSize: 16,
    // whiteSpace: 'pre-line',
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 16,
    // whiteSpace: 'pre-line',
  },
}));
export default StyledTableCell